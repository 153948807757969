import {
    TabList,
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    Box,
    Text,
    Button,
    Stack,
} from "@chakra-ui/react";
import React from "react";
import { MdSave } from "react-icons/md";
import About from "./BuildSteps/About";
import Education from "./BuildSteps/Education";
import Projects from "./BuildSteps/Projects";
import Skills from "./BuildSteps/Skills";
import Work from "./BuildSteps/Work";
import * as STRINGS from "../../resources/strings";
import { useResume } from "./ResumeProvider";
import SaveResumeUseCase from "../../domain/SaveResumeUseCase";
import { useCookies } from "react-cookie";

const Builder = () => {
    const [cookies] = useCookies([STRINGS.COOKIE_NAME]);
    /** Save Resume */
    const { about, educationList, skills, languages, workList, projects } =
        useResume();

    const saveResume = () => {
        const resume = {
            about,
            educationList,
            skills,
            languages,
            workList,
            projects,
        };
        //console.log("Resume Save - Click Save");
        new SaveResumeUseCase()
            .saveResumeUseCase(cookies[STRINGS.COOKIE_NAME], resume)
            .then((res) => {})
            .catch((e) => console.log("e", e));
    };

    return (
        <Box
            bg={"white"}
            w={"full"}
            rounded={"md"}
            shadow={"md"}
            overflow={"hidden"}
            paddingBottom={"10px"}
        >
            <Tabs isFitted variant="enclosed">
                <TabList>
                    <Tab>
                        <Text fontWeight={"medium"}>About</Text>
                    </Tab>
                    <Tab>
                        <Text fontWeight={"medium"}>Education</Text>
                    </Tab>
                    <Tab>
                        <Text fontWeight={"medium"}>Skills</Text>
                    </Tab>
                    <Tab>
                        <Text fontWeight={"medium"}>Work</Text>
                    </Tab>
                    <Tab>
                        <Text fontWeight={"medium"}>Projects</Text>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <About />
                    </TabPanel>
                    <TabPanel>
                        <Education />
                    </TabPanel>
                    <TabPanel>
                        <Skills />
                    </TabPanel>
                    <TabPanel>
                        <Work />
                    </TabPanel>
                    <TabPanel>
                        <Projects />
                    </TabPanel>
                </TabPanels>
                <Stack align={"center"}>
                    <Button
                        margin={"10px"}
                        w={"80%"}
                        variant="outline"
                        size={"sm"}
                        rightIcon={<MdSave />}
                        onClick={() => {
                            //Save CV on Database
                            saveResume();
                        }}
                        colorScheme={"blue"}
                    >
                        {STRINGS.SAVE}
                    </Button>
                </Stack>
            </Tabs>
        </Box>
    );
};

export default Builder;
