const InitPurchaseCategory = "Init Purchase";
const InitPurchaseInitHeader = "Init Purchase Header";
const InitPurchaseInitPricing = "Init Purchase Pricing";
const InitPurchaseInitCompleted = "Init Purchase Completed";

export default InitPurchaseCategory;
export {
    InitPurchaseCategory,
    InitPurchaseInitHeader,
    InitPurchaseInitPricing,
    InitPurchaseInitCompleted,
};
