import React from "react";
import styled from "styled-components";

// Assets
import CVIcon from "../../../Assets/img/cv.png";
import CheckMark from "../../../Assets//svg/Checkmark";
import { Box, Button, Image, Stack, Text } from "@chakra-ui/react";

export default function PricingTable({
    icon,
    price,
    discount,
    title,
    text,
    offers,
    action,
    style,
    background,
    action_title,
}) {
    let getIcon;

    switch (icon) {
        default: //<GreenIcon />;
            getIcon = (
                <Image
                    src={CVIcon}
                    alt="Pro version"
                    style={{ width: "50px" }}
                ></Image>
            );
            break;
    }

    return (
        <Box
            bg={background}
            rounded={"md"}
            shadow={"md"}
            overflow={"hidden"}
            p={4}
        >
            <Wrapper className="whiteBg radius8 shadow" style={style}>
                <Stack direction="row">
                    {/*<Box>{getIcon}</Box>*/}
                    <Stack
                        direction={"column"}
                        textAlign="right"
                        boxSize={"full"}
                    >
                        {discount.includes("undefined") && (
                            <Text
                                color={"gray.600"}
                                lineHeight={1.7}
                                style={{
                                    fontFamily: "Poppins",
                                }}
                                as={"b"}
                                fontSize={{ base: "4xl", md: "5xl" }}
                                bgGradient="linear(to-r, purple.500, blue.600)"
                                bgClip="text"
                            >
                                {price}
                            </Text>
                        )}
                        {!discount.includes("undefined") && (
                            <>
                                <Text
                                    color={"gray.600"}
                                    lineHeight={1.7}
                                    fontSize={{
                                        base: "1xl",
                                        md: "2xl",
                                    }}
                                    style={{
                                        fontFamily: "Poppins",
                                        textDecoration: "line-through",
                                    }}
                                >
                                    {price}
                                </Text>

                                <Text
                                    color={"gray.600"}
                                    lineHeight={1.7}
                                    style={{
                                        fontFamily: "Poppins",
                                    }}
                                    as={"b"}
                                    fontSize={{ base: "3xl", md: "4xl" }}
                                    bgGradient="linear(to-r, purple.500, blue.600)"
                                    bgClip="text"
                                >
                                    {discount}
                                </Text>
                            </>
                        )}
                    </Stack>
                </Stack>

                <Stack>
                    <Text
                        as={"b"}
                        bgGradient="linear(to-r, purple.500, blue.600)"
                        bgClip="text"
                        fontSize={{ base: "2xl", md: "3xl" }}
                    >
                        {title}
                    </Text>
                    <Text
                        color={"gray.600"}
                        lineHeight={1.7}
                        style={{ fontFamily: "Poppins" }}
                    >
                        {text}
                    </Text>
                </Stack>

                <Box mt={"10"}>
                    {offers
                        ? offers.map((item, index) => (
                              <Stack
                                  direction={"row"}
                                  style={{ margin: "15px 0" }}
                                  key={index}
                              >
                                  <div
                                      style={{
                                          position: "relative",
                                          top: "-1px",
                                          marginRight: "15px",
                                      }}
                                  >
                                      {item.checked ? (
                                          <div style={{ minWidth: "20px" }}>
                                              <CheckMark />
                                          </div>
                                      ) : (
                                          <div
                                              style={{ minWidth: "20px" }}
                                          ></div>
                                      )}
                                  </div>
                                  <Text
                                      color={"gray.600"}
                                      lineHeight={1.7}
                                      style={{
                                          fontFamily: "Poppins",
                                      }}
                                  >
                                      {item.name}
                                  </Text>
                              </Stack>
                          ))
                        : null}
                </Box>

                <Stack>
                    <Button
                        as={"a"}
                        href={"#builder"}
                        rounded={"md"}
                        size={"lg"}
                        px={6}
                        colorScheme={"purple"}
                        onClick={() => action()}
                    >
                        {action_title}
                    </Button>
                </Stack>
            </Wrapper>
        </Box>
    );
}

const Wrapper = styled.div`
    width: 100%;
    text-align: left;
    padding: 20px 30px;
    margin-top: 30px;
`;
