import FirebaseDataprovider from "../infraestructure/Firebase/index";

class ExistsOrderUseCase {
    async existsOrderUseCase(id) {
        return FirebaseDataprovider.order(id)
            .once("value")
            .then(function (snapshot) {
                return snapshot.val() !== null ? true : false;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default ExistsOrderUseCase;
