import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    useToast,
    Checkbox,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import CreateUserUseCase from "../domain/CreateUserUseCase";
import { useNavigate } from "react-router-dom";

import * as STRINGS from "../resources/strings";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import SignUpCategory, {
    OpenSignUp,
} from "../resources/googleAnalytics/events/SignUpEvent";
import { useCookies } from "react-cookie";
import SendEmailUseCase from "../domain/SendEmailUseCase";
import useReactIpLocation from "react-ip-details";
import getSymbolFromCurrency from "currency-symbol-map";
import PRICES from "../resources/prices.js";

export default function SignUp() {
    const toast = useToast();
    const navigate = useNavigate();
    const [cookies] = useCookies([STRINGS.COOKIE_NAME, STRINGS.COOKIE_USER]);
    const [showPassword, setShowPassword] = useState(false);

    const [email, setEmail] = useState(false);
    const [pass, setPass] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [order, setOrder] = useState(cookies[STRINGS.COOKIE_NAME]);
    const [isMega, setIsMega] = useState(cookies[STRINGS.COOKIE_USER]);
    const { currency } = useReactIpLocation({ numberToConvert: 100 });
    var symbol = getSymbolFromCurrency(currency);

    if (symbol !== "€" && symbol !== "$") {
        symbol = "$";
    }

    useEffect(() => {
        TrackGoogleAnalyticsEvent(
            SignUpCategory,
            OpenSignUp,
            window.location.pathname
        );
    }, []);

    const signup = () => {
        if (email.length === 0 || pass.length === 0) {
            toast({
                title: STRINGS.SIGN_UP_ERROR,
                status: "error",
                isClosable: true,
            });
        } else {
            setIsLoading(true);
            new CreateUserUseCase()
                .doCreateUserUseCase(email, pass, order, isMega)
                .then((res) => {
                    setIsLoading(false);
                    var version = isMega === "true" ? "PREMIUM" : "";

                    const price_id = isMega === "true" ? "offer-2" : "offer-1";
                    var amount = "";
                    PRICES.map((offer) => {
                        if (offer.id === price_id) {
                            const p = offer.discount
                                ? offer.discount
                                : offer.price;
                            amount = p + symbol;
                        }
                    });

                    new SendEmailUseCase()
                        .sendEmailBuy(email, version, amount)
                        .then()
                        .catch()
                        .finally(() => {
                            window.location = STRINGS.URL_BUILDER;
                        });

                    //navigate(STRINGS.URL_BUILDER);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast({
                        title: err.message,
                        status: "error",
                        isClosable: true,
                    });
                });
        }
    };

    return (
        <>
            <Flex
                minH={"100vh"}
                align={"center"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
            >
                <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                    <Stack align={"center"}>
                        <Heading fontSize={"4xl"} textAlign={"center"}>
                            Let's to create your user
                        </Heading>
                        <Text fontSize={"lg"} color={"gray.600"}>
                            to enjoy all of our cool features ✌️
                        </Text>
                    </Stack>
                    <Box
                        rounded={"lg"}
                        bg={useColorModeValue("white", "gray.700")}
                        boxShadow={"lg"}
                        p={8}
                    >
                        <Stack spacing={4}>
                            {/*<HStack>
                                <Box>
                                    <FormControl id="firstName" isRequired>
                                        <FormLabel>First Name</FormLabel>
                                        <Input
                                            type="text"
                                            onChange={(evt) =>
                                                setFirstName(evt.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl id="lastName" isRequired>
                                        <FormLabel>Last Name</FormLabel>
                                        <Input
                                            type="text"
                                            onChange={(evt) =>
                                                setLastName(evt.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            </HStack> */}
                            <FormControl id="email" isRequired>
                                <FormLabel>Email address</FormLabel>
                                <Input
                                    type="email"
                                    onChange={(evt) =>
                                        setEmail(evt.target.value)
                                    }
                                />
                            </FormControl>
                            <FormControl id="password" isRequired>
                                <FormLabel>Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        onChange={(evt) =>
                                            setPass(evt.target.value)
                                        }
                                    />
                                    <InputRightElement h={"full"}>
                                        <Button
                                            variant={"ghost"}
                                            onClick={() =>
                                                setShowPassword(
                                                    (showPassword) =>
                                                        !showPassword
                                                )
                                            }
                                        >
                                            {showPassword ? (
                                                <ViewIcon />
                                            ) : (
                                                <ViewOffIcon />
                                            )}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            {/*<Stack
                                direction={{ base: "column", sm: "row" }}
                                align={"start"}
                                justify={"space-between"}
                            >
                                <Checkbox
                                    colorScheme={"purple"}
                                    onChange={(evt) => {
                                        setIsChecked(evt.target.checked);
                                    }}
                                >
                                    {STRINGS.ACCEPT_TERMS}{" "}
                                    <Link
                                        color={"purple.400"}
                                        href="/privacy-policy"
                                    >
                                        Privacy Policy
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        color={"purple.400"}
                                        href="/terms-and-conditions"
                                    >
                                        T&C
                                    </Link>
                                </Checkbox>
                                </Stack>*/}
                            <Stack spacing={10} pt={2}>
                                <Button
                                    loadingText="Submitting"
                                    size="lg"
                                    bg={"purple.400"}
                                    color={"white"}
                                    _hover={{
                                        bg: "purple.500",
                                    }}
                                    onClick={() => {
                                        signup();
                                    }}
                                    isLoading={isLoading}
                                >
                                    Confirm
                                </Button>
                            </Stack>
                            {/*<Stack pt={6}>
                                <Text align={"center"}>
                                    Already a user?{" "}
                                    <Link
                                        color={"purple.400"}
                                        href={STRINGS.URL_SIGN_IN}
                                    >
                                        Login
                                    </Link>
                                </Text>
                                </Stack>*/}
                        </Stack>
                    </Box>
                </Stack>
            </Flex>
        </>
    );
}
