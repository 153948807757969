import { createContext, useContext, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import * as STRINGS from "../../resources/strings";
const ResumeContext = createContext();

export const useResume = () => useContext(ResumeContext);

export const ResumeProvider = ({ children }) => {
    const printElem = useRef();

    const [theme, setTheme] = useState("purple.400");

    const [about, setAbout] = useState({
        name: "",
        role: "",
        email: "",
        phone: "",
        address: "",
        linkedin: "",
        picture: "",
    });

    const emptyEducation = {
        id: "",
        degree: "",
        school: "",
        startYr: 0,
        endYr: 0,
        grade: "",
    };
    const [educationList, setEducationList] = useState([emptyEducation]);

    const [skills, setSkills] = useState([]);

    const [languages, setLanguages] = useState([]);

    const emptyWork = {
        id: "",
        position: "",
        company: "",
        type: "",
        startDate: "",
        endDate: "",
        description: "",
    };
    const [workList, setWorkList] = useState([emptyWork]);

    const emptyProject = {
        id: "",
        name: "",
        description: "",
        url: "",
    };
    const [projects, setProjects] = useState([emptyProject]);

    // useEffect(() => {
    //     toast({
    //         title: `${theme.split(".", 1)} selected`,
    //         status: 'success',
    //         isClosable: true,
    //       })
    // }, [theme]);

    const value = {
        about,
        setAbout,
        educationList,
        setEducationList,
        skills,
        setSkills,
        languages,
        setLanguages,
        workList,
        setWorkList,
        projects,
        setProjects,
        printElem,
        theme,
        setTheme,
        emptyWork,
        emptyProject,
        emptyEducation,
    };

    return (
        <ResumeContext.Provider value={value}>
            {children}
        </ResumeContext.Provider>
    );
};
