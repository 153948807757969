import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Textarea,
    Select,
    Text,
    Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useResume } from "../ResumeProvider";
import { v4 as uuidv4 } from "uuid";
import { MdDelete } from "react-icons/md";
import * as STRINGS from "../../../resources/strings";
import ImproveWorkDescriptionUseCase from "../../../domain/ImproveWorkDescriptionMeUseCase";

const Work = () => {
    const { workList, setWorkList, emptyWork } = useResume();
    const [isImprove, setIsImprove] = useState(false);
    const [index, setIndex] = useState(0);

    const addMore = () => {
        setWorkList([...workList, emptyWork]);
        setIndex(workList.length);
    };

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        const updatedWorkList = workList.map((work) =>
            work.id === id
                ? Object.assign(work, { id: uuidv4(), [name]: value })
                : work
        );

        setWorkList(updatedWorkList);
    };

    const deleteWork = (id) => {
        setWorkList(workList.filter((elem) => elem.id !== id));
        setIndex((lastIndex) => {
            if (lastIndex === 0) {
                return 0;
            }
            return lastIndex - 1;
        });
    };

    const onImprove = (id) => {
        setIsImprove(true);
        workList.map((work) => {
            if (work.id === id) {
                new ImproveWorkDescriptionUseCase()
                    .improveWorkDescriptionUseCase(work)
                    .then((res) => {
                        setIsImprove(false);
                        const updatedWorkList = workList.map((work) =>
                            work.id === id
                                ? Object.assign(work, {
                                      id: uuidv4(),
                                      description: res,
                                  })
                                : work
                        );

                        setWorkList(updatedWorkList);
                    })
                    .catch((err) => {
                        setIsImprove(false);
                        console.log("Error Work", err);
                    });
            }
        });
    };

    return (
        <>
            <Accordion allowToggle defaultIndex={[0]} index={index}>
                {workList.map((work, index) => (
                    <AccordionItem key={index}>
                        <h2>
                            <AccordionButton onClick={() => setIndex(index)}>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight={"medium"}>
                                        {work.position
                                            ? work.position
                                            : "Position"}
                                    </Text>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Input
                                value={work.position}
                                onChange={(e) => handleChange(e, work.id)}
                                name="position"
                                type="text"
                                variant="filled"
                                placeholder="Position"
                                mb={3}
                            />

                            <HStack spacing={3}>
                                <Input
                                    value={work.company}
                                    onChange={(e) => handleChange(e, work.id)}
                                    name="company"
                                    type="text"
                                    variant="filled"
                                    placeholder="Company"
                                />
                                <Select
                                    value={work.type}
                                    onChange={(e) => handleChange(e, work.id)}
                                    name="type"
                                    variant="filled"
                                    placeholder="Employment Type"
                                >
                                    <option value="Full-time">Full-time</option>
                                    <option value="Part-time">Part-time</option>
                                    <option value="Internship">
                                        Internship
                                    </option>
                                    <option value="Freelance">Freelance</option>
                                </Select>
                            </HStack>

                            <HStack spacing={3} mt={4}>
                                <FormControl>
                                    <FormLabel htmlFor="startDate">
                                        Start Date
                                    </FormLabel>
                                    <Input
                                        value={work.startDate}
                                        onChange={(e) =>
                                            handleChange(e, work.id)
                                        }
                                        name="startDate"
                                        id="startDate"
                                        type="month"
                                        variant="filled"
                                        placeholder="Start Date"
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel htmlFor="endDate">
                                        End Date
                                    </FormLabel>
                                    <Input
                                        value={work.endDate}
                                        onChange={(e) =>
                                            handleChange(e, work.id)
                                        }
                                        name="endDate"
                                        id="endDate"
                                        type="month"
                                        variant="filled"
                                        placeholder="Start Date"
                                    />
                                </FormControl>
                            </HStack>

                            <FormControl mt={3}>
                                <FormLabel htmlFor="description">
                                    Description
                                </FormLabel>
                                <Textarea
                                    value={work.description}
                                    onChange={(e) => handleChange(e, work.id)}
                                    name="description"
                                    id="description"
                                    variant="filled"
                                    placeholder="Description..."
                                />
                            </FormControl>
                            <Stack direction="row" spacing={4}>
                                <Button
                                    isLoading={isImprove}
                                    colorScheme="purple"
                                    variant="link"
                                    loadingText={STRINGS.IMPROVING}
                                    size={"xs"}
                                    onClick={() => onImprove(work.id)}
                                >
                                    {STRINGS.IMPROVE}
                                </Button>
                            </Stack>

                            <Button
                                rightIcon={<MdDelete />}
                                onClick={() => deleteWork(work.id)}
                                mt={3}
                                colorScheme={"red"}
                            >
                                Delete
                            </Button>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>

            <Button colorScheme={"purple"} my={5} onClick={addMore}>
                Add More
            </Button>
        </>
    );
};

export default Work;
