"use client";
import {
  Flex,
  Stack,
  Text,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
} from "@chakra-ui/react";

import * as STRINGS from "../../resources/strings";
import { avatars } from "../../resources/avatars";

const AvatarsRow = () => {
  const avatarsImages = avatars.slice(0, 5);

  return (
    <Stack direction={"row"} spacing={4} align={"center"}>
      <AvatarGroup>
        {avatarsImages.map((avatar) => (
          <Avatar
            key={avatar.name}
            name={avatar.name}
            src={avatar.url}
            // eslint-disable-next-line react-hooks/rules-of-hooks
            size={useBreakpointValue({
              base: "sm",
              md: "sm",
            })}
            position={"relative"}
            zIndex={2}
            _before={{
              content: '""',
              width: "full",
              height: "full",
              rounded: "full",
              transform: "scale(1.125)",
              bgGradient: "linear(to-bl, purple.500, blue.600)",
              position: "absolute",
              zIndex: -1,
              top: 0,
              left: 0,
            }}
          />
        ))}
      </AvatarGroup>
      <Text fontFamily={"heading"} fontSize={{ base: "4xl", md: "6xl" }}>
        +
      </Text>
      <Flex
        align={"center"}
        justify={"center"}
        fontFamily={"heading"}
        fontSize={{ base: "sm", md: "lg" }}
        bg={"white"}
        color={"purple"}
        rounded={"full"}
        minWidth={useBreakpointValue({
          base: "44px",
          md: "60px",
        })}
        minHeight={useBreakpointValue({
          base: "44px",
          md: "60px",
        })}
        position={"relative"}
        _before={{
          content: '""',
          width: "full",
          height: "full",
          rounded: "full",
          transform: "scale(1.125)",
          bgGradient: "linear(to-bl, purple.500, blue.600)",
          position: "absolute",
          zIndex: -1,
          top: 0,
          left: 0,
        }}
      >
        {STRINGS.YOU}
      </Flex>
    </Stack>
  );
};

export default AvatarsRow;
