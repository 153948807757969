import FirebaseDataprovider from "../infraestructure/Firebase";

class DoLoginUseCase {
    async doLoginUseCase(username, pass) {
        return FirebaseDataprovider.doSignInWithEmailAndPassword(username, pass)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default DoLoginUseCase;
