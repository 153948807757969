const PRICES = [
    {
        id: "offer-3",
        icon: "shield_green",
        background: "white",
        price: "9.90",
        //discount: "4.95",
        title: "Resume Review",
        description: "Upload your resume and get amazing review in seconds",
        offers: [
            { name: "Value input feedback", checked: true },
            { name: "Feedback in seconds", checked: true },
            { name: "Amount of key points to improve", checked: true },
            { name: "Only one feedback", checked: true },
        ],
        actionTitle: "Get Review",
        url: process.env.REACT_APP_STRIPE_OFFER3,
    },
    {
        id: "offer-1",
        icon: "shield_green",
        background: "white",
        price: "19.90",
        //discount: "14.99",
        title: "Resume builder",
        description:
            "Don't let a mediocre resume hold you back from landing your dream job. With ResumeBuilderAI, you can easily create a professional, standout resume in just minutes.",
        offers: [
            { name: "Powerful Resume", checked: true },
            { name: "Export in PDF", checked: true },
            { name: "Multiple change reviews", checked: true },
            { name: "Resume Feedback in seconds", checked: true },
            { name: "Multiples Resume Feedbacks", checked: true },
        ],
        actionTitle: "Get it",
        url: process.env.REACT_APP_STRIPE_OFFER1,
    },
    {
        id: "offer-2",
        icon: "shield_green",
        background: "#EEE6FF",
        price: "29",
        //discount: "99",
        title: "Premium",
        description:
            "Introducing our better offer to help you land your dream job! With our professional resume creation service, interview preparation and the cover letter generator.",
        offers: [
            { name: "Resume Feedback in seconds", checked: true },
            { name: "Multiples Resume Feedbacks", checked: true },
            { name: "Powerful Resume", checked: true },
            { name: "Cover Letter in seconds", checked: true },
            {
                name: "Interview Questions & Answers",
                checked: true,
            },
            { name: "Multiple change reviews", checked: true },
            { name: "Export in PDF", checked: true },
            { name: "Support Assistant", checked: true },
        ],
        actionTitle: "Land an interview NOW",
        url: process.env.REACT_APP_STRIPE_OFFER2,
    },
];

export default PRICES;
