import FirebaseDataprovider from "../../infraestructure/Firebase";

class CreateUserAuthUseCase {
    async doCreateUserAuthUseCase(username, pass) {
        return FirebaseDataprovider.doCreateUserWithEmailAndPassword(
            username,
            pass
        )
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default CreateUserAuthUseCase;
