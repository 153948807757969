import React, { useEffect } from "react";

//import ReactPixel from "react-facebook-pixel";
import * as STRINGS from "../resources/strings";
import { Box, Text } from "@chakra-ui/react";
import PrivacyCategory, {
  InitPrivacy,
} from "../resources/googleAnalytics/events/PrivacyEvent";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import { Helmet } from "react-helmet";
export default function Privacy() {
  //ReactPixel.trackCustom("privacy", "init");

  useEffect(() => {
    TrackGoogleAnalyticsEvent(
      PrivacyCategory,
      InitPrivacy,
      window.location.pathname
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{STRINGS.TITLE_WEB_HOME}</title>
        <link rel="canonical" href={STRINGS.URL_BASE + STRINGS.URL_PRIVACY} />
      </Helmet>
      <Box style={{ padding: "30px" }}>
        <Text fontSize="2xl" as={"b"}>
          Privacy Policy
        </Text>
        <Text>
          Privacy is important to {STRINGS.APP_NAME}. This privacy policy
          ("Privacy Policy") applies to the {STRINGS.APP_NAME} websites and
          explains how personal information is collected, used, disclosed, and
          protected by {STRINGS.APP_NAME}.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Changes to This Privacy Policy
        </Text>
        <Text>
          We may change this Privacy Policy from time to time. If we make any
          changes to this Privacy Policy, we will inform you by posting the
          revised Privacy Policy on the website. Those changes will go into
          effect on the "last updated" date shown in the revised Privacy Policy.
          You consent to the revised Privacy Policy by continuing to use{" "}
          {STRINGS.APP_NAME} Services. We encourage you to check this Privacy
          Policy whenever you use {STRINGS.APP_NAME} Services to understand how
          your personal information is used.
        </Text>
        <Text fontSize="lg" as={"b"}>
          What Does This Privacy Policy Cover?
        </Text>
        <Text>
          In your dealings with {STRINGS.APP_NAME} you may provide us with
          personal information. Personal information for the purposes of this
          Privacy Policy means information that personally identifies you, like
          your name, street address, email address, phone number, and other
          personally identifiable information that you choose to give to us or
          that we collect in relation to your use of the {STRINGS.APP_NAME}{" "}
          Services. This Privacy Policy does not apply to the practices of
          entities that we do not own or control, or to individuals that we do
          not employ or manage.
        </Text>
        <Text fontSize="lg" as={"b"}>
          No information from Minors under 13
        </Text>
        <Text>
          If you are a minor less than 13 years old, please do not register for{" "}
          {STRINGS.APP_NAME} Services or provide any personal information about
          yourself to us. If we learn that we have collected personal
          information from such a minor, we will delete that information. If you
          are a parent of a minor, you may provide us with personal information
          about the minor, or permit us to collect information directly from the
          minor (if he or she is at least 13 years old), to use as part of
          {STRINGS.APP_NAME} Services and we will treat it in accordance with
          this Privacy Policy.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Information Collected
        </Text>
        <Text>
          We collect information from you in various ways when you use
          {STRINGS.APP_NAME} Services. We may also supplement this information
          with information from other companies. We collect two general types of
          information, namely personal information (defined above) and aggregate
          data. Aggregate data is information we collect about a group or
          category of services or users from which individual user identities
          have been removed. In other words, no personal information is included
          in aggregate data. Aggregate data helps us understand trends in our
          users' needs so that we can better consider new features or otherwise
          tailor our services. This Privacy Policy in no way restricts or limits
          our collection and use of aggregate data, and we may share aggregate
          data about our users with third parties for various purposes,
          including to help us better understand our customer needs and improve
          our services and for advertising and marketing purposes. The following
          are the specific types of information we collect from you: Information
          You Give Us. We collect information you give us through{" "}
          {STRINGS.APP_NAME} Services and when you register for and use{" "}
          {STRINGS.APP_NAME} Services. Examples include the following:
        </Text>
        <Text>
          <b>Registration and Resume Information</b> When you register to use{" "}
          {STRINGS.APP_NAME} Services or update your profile, we may collect
          various kinds of information about you including, your name, username,
          phone number, addresses, photos, email addresses, date of birth, about
          you information, experience, studies, skills, laguages and projects.
          Information we collect when you interact with
          {STRINGS.APP_NAME}. We collect personal information of the actions
          taken while using or interacting with {STRINGS.APP_NAME}. When you use{" "}
          {STRINGS.APP_NAME} Services, we automatically collect information from
          your browser or mobile platform including your browser type, location,
          IP address, as well as the pages you visit. We may retain the details
          of transactions or payments you make on or related to{" "}
          {STRINGS.APP_NAME}
          Services.
        </Text>

        <Text>
          <b>Automatically Collected Information</b> We automatically receive
          certain types of information when you interact with
          {STRINGS.APP_NAME} and communications. For example, it is standard for
          your Web browser to automatically send information to every Web site
          you visit, including ours. That information includes your computer's
          IP address, access times, your browser type and language, and
          referring Web site addresses. We may also collect information about
          the type of operating system you use, your account activity, and files
          and pages accessed or used by you.
        </Text>

        <Text>
          <b>Cookies and Web Beacons</b> We may also use certain kinds of
          technology such as cookies and Web beacons to collect information.
          Among other things, the use of cookies and Web beacons enables us to
          improve {STRINGS.APP_NAME} and emails by seeing which areas and
          features are most popular, to count the number of computers, mobile
          phones or other devices accessing {STRINGS.APP_NAME}, to personalize
          and improve your experience, to record your preferences, and to allow
          you to visit {STRINGS.APP_NAME} without reentering your member ID
          and/or password. A cookie is a small amount of data which is sent to
          your browser from a Web site's computers and stored on your computer's
          hard drive. Most browsers automatically accept cookies as the default
          setting. You can modify your browser setting to reject our cookies or
          to prompt you before accepting a cookie by editing your browser
          options. However, if a browser is set not to accept cookies or if a
          user rejects a cookie, some portions of {STRINGS.APP_NAME} may not
          function properly. For example, you may not be able to sign in and
          access certain features of the {STRINGS.APP_NAME}. A Web beacon is an
          electronic image, also called a "gif," that may be used on{" "}
          {STRINGS.APP_NAME} to deliver cookies, count visits and compile
          statistics on usage or in our emails to tell if an email has been
          opened and acted upon. We also use Google Analytics to help us
          understand how users engage with our Services. The information
          collected and how it is used is described at
          https://www.google.com/intl/en/analytics/privacyoverview.html
        </Text>

        <Text fontSize="lg" as={"b"}>
          Use of Personal Information
        </Text>

        <Text>
          In general, we use your personal information to process your requests
          or transactions, to provide you with information or services you
          request, to inform you about other information, events, promotions,
          products or services we think will be of interest to you, to
          facilitate your use of and our administration and operation of{" "}
          {STRINGS.APP_NAME}, and to otherwise provide {STRINGS.APP_NAME} and
          serve you and our users. For example, we may use your personal
          information: to request feedback and to enable us to develop,
          customize and improve our publications, products and{" "}
          {STRINGS.APP_NAME}; to conduct marketing analysis, to send you surveys
          or newsletters, to contact you about services, products, activities,
          offers from {STRINGS.APP_NAME} or our partners and for other
          marketing, informational, product development and promotional
          purposes; to send you a welcoming email and to contact you about your
          use of {STRINGS.APP_NAME}; to respond to your emails, submissions,
          comments, requests or complaints; to perform after sales services; to
          anticipate and resolve problems with our service; to respond to
          customer support inquiries, for assistance with our product and
          service development; and to inform you of updates to products and
          services from {STRINGS.APP_NAME} that better meet your needs; and for
          other purposes about which we notify you.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Sharing of Personal Information
        </Text>
        <Text>
          {STRINGS.APP_NAME} serves the right to share aggregated demographic
          information about our customers (including you), sales, and traffic to
          our partners and advertisers. We will not give, sell, rent, share, or
          trade any of your personal information or any data that you store
          using our services to any third party except as outlined in this
          Privacy Policy or with your consent. Every once in a while, we may
          start a business relationship with other businesses who we think are
          trustworthy and who have confirmed that their privacy practices are
          consistent with ours ("Service Providers"). For example, we may hire
          Service Providers to provide services like hosting and maintenance,
          data storage and management, and marketing and promotions. We only
          provide our Service Providers with the information they need to
          perform these services for us. Each Service Provider must agree to use
          reasonable security procedures and practices, appropriate to the type
          of information involved, in order to protect your Personal Information
          from unauthorized access, use or disclosure. Service Providers are
          prohibited from using Personal Information except as we tell them. We
          may share personal information in connection with an acquisition,
          merger, or sale of all or a substantial portion of our business, with
          or to another company. In any such event, you will receive notice if
          your data is transferred and becomes subject to a substantially
          different privacy policy.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Resume Feedback Tool
        </Text>
        <Text>
          {STRINGS.APP_NAME} offers a Resume Feedback tool that utilizes a
          third-party endpoint provided by OpenAI. When you use this tool,{" "}
          {STRINGS.APP_NAME} may share your resume picture with OpenAI for
          analysis and feedback. Additionally, a copy of your resume will be
          stored securely on Firebase for a period of 30 days. The storage on
          Firebase is designed to enhance your experience by allowing you to
          access and review your resume feedback.
        </Text>
        <Text>
          It's important to note that the information shared with OpenAI and
          stored on Firebase is treated with the utmost confidentiality and is
          used solely for the purpose of providing you with the Resume Feedback
          service. This information will not be shared, sold, or disclosed to
          any third party, except as outlined in this Privacy Policy or with
          your explicit consent.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Network and Information Security
        </Text>
        <Text>
          {STRINGS.APP_NAME} takes reasonable steps to protect information we
          collect from you to prevent loss, misuse and unauthorized access,
          disclosure, alteration, and destruction. The servers on which
          information is stored are kept in a controlled environment with
          limited access. While we take reasonable efforts to guard personal
          information we knowingly collect directly from you, no security system
          is impenetrable. In addition, we cannot guarantee that any passively
          collected personal information you choose to include in documents you
          store on our systems or the systems of third party storage providers
          through {STRINGS.APP_NAME} are maintained at adequate levels of
          protection to meet specific needs or obligations you may have relating
          to that information. Your account information and access to our
          service is accessible only through the use of an individual user ID
          and password. To protect the confidentiality of personal information,
          you must keep your password confidential and not disclose it to any
          other person. Please advise us immediately if you believe your
          password has been misused. In addition, always logout and close your
          browser when you finish your session. Please note that we will never
          ask you to disclose your password in an unsolicited phone call or
          email.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Choice/OptOut
        </Text>
        <Text>
          {STRINGS.APP_NAME} may send you communications or data regarding{" "}
          {STRINGS.APP_NAME}
          Services, including but not limited to (i) notices about your use of{" "}
          {STRINGS.APP_NAME} Services, including any notices concerning
          violations of use, (ii) updates, and (iii) promotional information and
          materials regarding our products and services. You may optout of
          receiving promotional emails from {STRINGS.APP_NAME} by following the
          optout instructions provided in those emails. You may also optout of
          receiving promotional emails and other promotional communications from
          us at any time by emailing {STRINGS.SUPPORT_EMAIL} with your specific
          request.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Enforcement
        </Text>
        <Text>
          If you have any questions about this Privacy Policy, you should first
          contact us at {STRINGS.SUPPORT_EMAIL}.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Contacting Us
        </Text>
        <Text>
          Any questions about this Privacy Policy should be addressed to{" "}
          {STRINGS.SUPPORT_EMAIL}
        </Text>
        <Text mt={5}>Updated at: 4 / December / 2024</Text>
      </Box>
    </>
  );
}
