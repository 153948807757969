import OpenAiDataProvider from "../infraestructure/OpenAi";

class InterviewQuestionsUseCase {
    async interviewQuestionsUseCase(text) {
        const prompt = `${text}`;
        return OpenAiDataProvider.completionText35(prompt)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default InterviewQuestionsUseCase;
