import React, { useEffect } from "react";
import * as STRINGS from "../resources/strings";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Container,
    Divider,
    Image,
    Input,
    Stack,
    Text,
    Textarea,
} from "@chakra-ui/react";

import { useCookies } from "react-cookie";
import ExistsOrderUseCase from "../domain/ExistsOrderUseCase";
import CoverLetterUseCase from "../domain/CoverLetterUseCase";
import { useResume } from "../Components/ResumeBuilder/ResumeProvider";
import LoadResumeUseCase from "../domain/LoadResumeUseCase";

import MYImage from "../Assets/svg/cover-letter.svg";

const CoverLetterPage = () => {
    const [cookies] = useCookies([STRINGS.COOKIE_NAME]);
    const [yourName, setYourName] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [jobOffer, setJobOffer] = React.useState("");
    const [questions, setQuestions] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    /** Save Resume */
    const {
        about,
        setAbout,
        educationList,
        setEducationList,
        skills,
        setSkills,
        languages,
        setLanguages,
        workList,
        setWorkList,
        projects,
        setProjects,
    } = useResume();

    const [alert, setAlert] = React.useState({
        show: false,
        title: "",
        description: "",
        status: "success",
    });

    useEffect(() => {
        new ExistsOrderUseCase()
            .existsOrderUseCase(cookies[STRINGS.COOKIE_NAME])
            .then((res) => {
                if (res) {
                } else {
                    return (window.location = STRINGS.STRIPE_URL);
                }
            })
            .catch((e) => console.log("e", e));

        new LoadResumeUseCase()
            .loadResumeUseCase(cookies[STRINGS.COOKIE_NAME])
            .then((res) => {
                if (res) {
                    setAbout(res.about);
                    setYourName(res.about.name);
                    setLanguages(
                        res.languages === undefined ? [] : res.languages
                    );

                    setSkills(res.skills === undefined ? [] : res.skills);
                    if (
                        res.educationList === null ||
                        res.educationList.length === 0
                    ) {
                        setEducationList({
                            id: "",
                            degree: "",
                            school: "",
                            startYr: 0,
                            endYr: 0,
                            grade: "",
                        });
                    } else {
                        setEducationList(res.educationList);
                    }

                    if (res.projects === null || res.projects.length === 0) {
                        setProjects({
                            id: "",
                            name: "",
                            description: "",
                            url: "",
                        });
                    } else {
                        setProjects(res.projects);
                    }

                    if (res.workList === null || res.workList.length === 0) {
                        setWorkList({
                            id: "",
                            position: "",
                            company: "",
                            type: "",
                            startDate: "",
                            endDate: "",
                            description: "",
                        });
                    } else {
                        setWorkList(res.workList);
                    }
                } else {
                    //Resume don't exist
                }
            })
            .catch((e) => console.log("e", e));
    }, [cookies]);

    const askToChatContact = () => {
        setIsLoading(true);
        new CoverLetterUseCase()
            .coverLetterUseCase(jobOffer, yourName, companyName)
            .then((res) => {
                setIsLoading(false);
                setJobOffer("");
                setCompanyName("");
                setQuestions(res);
            })
            .catch((err) => {
                console.log("err".err);
                setIsLoading(false);
            });
    };

    return (
        <Box>
            <Container maxW={"7xl"} px={8} my={3}>
                <Box
                    bg={"white"}
                    rounded={"md"}
                    shadow={"md"}
                    overflow={"hidden"}
                    p={3}
                    mb={5}
                >
                    <Stack>
                        <Image
                            alt={"Resume Builder AI"}
                            fit={"contain"}
                            align={"center"}
                            w={"100%"}
                            h={"300px"}
                            m={"20px"}
                            src={MYImage}
                            draggable="false"
                        />
                    </Stack>
                    <Stack spacing={3}>
                        <Text fontSize="2xl" as={"b"}>
                            {STRINGS.COVERLETTER_TITLE}
                        </Text>
                        <Text fontSize="m">
                            {STRINGS.COVERLETTER_DESCRIPTION}
                        </Text>
                        <Input
                            placeholder={STRINGS.COVERLETTER_YOUR_NAME}
                            variant="outline"
                            value={yourName}
                            onChange={(event) =>
                                setYourName(event.target.value)
                            }
                        />

                        <Input
                            placeholder={STRINGS.COVERLETTER_COMPANY_NAME}
                            variant="outline"
                            value={companyName}
                            onChange={(event) =>
                                setCompanyName(event.target.value)
                            }
                        />
                        <Textarea
                            value={jobOffer}
                            variant="outline"
                            placeholder={STRINGS.COVERLETTER_JOB_OFFER}
                            onChange={(event) =>
                                setJobOffer(event.target.value)
                            }
                        />
                        <Button
                            onClick={() => askToChatContact()}
                            isLoading={isLoading}
                            loadingText={STRINGS.COVERLETTER_WAITING}
                            disabled={jobOffer.length < 100}
                            colorScheme="purple"
                            variant={"outline"}
                        >
                            {STRINGS.COVERLETTER_SEND}
                        </Button>
                        <Divider></Divider>
                        {questions !== "" && (
                            <Text fontSize="2xl" as={"b"}>
                                {STRINGS.COVERLETTER_TITLE}
                            </Text>
                        )}
                        <div style={{ whiteSpace: "pre-wrap" }}>
                            {questions}
                        </div>
                    </Stack>
                </Box>
                {alert.show && (
                    <Alert status={alert.status}>
                        <AlertIcon />
                        <AlertTitle>{alert.title}</AlertTitle>
                        <AlertDescription>{alert.description}</AlertDescription>
                    </Alert>
                )}
            </Container>
        </Box>
    );
};

export default CoverLetterPage;
