import React from "react";
import { Link as RouterLink } from "react-router-dom";
// Components
import {
  Box,
  Button,
  Divider,
  Heading,
  Image,
  Container,
  SimpleGrid,
  VStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { renderToString } from "react-dom/server";
import { Card, CardBody, CardFooter } from "@chakra-ui/card";
import * as STRINGS from "../../resources/strings";
import BLOGS, { removeTags } from "../../resources/blogs/blogs.js";

export default function BlogCarousel() {
  const blogsKeys = Object.keys(BLOGS).slice(-3);
  return (
    <Container maxW="7xl" as="main" style={{ paddingBottom: "10px" }}>
      <VStack spacing={4} align="stretch">
        <Box>
          <Heading
            fontSize={{ base: "3xl", md: "5xl" }}
            bgGradient="linear(to-r, purple.500, blue.600)"
            bgClip="text"
            style={{ cursor: "pointer" }}
            onClick={() => (window.location = "/blog")}
          >
            {STRINGS.BLOG_TITLE}
          </Heading>
        </Box>
        <SimpleGrid spacing={4} columns={{ sm: 2, md: 3 }}>
          {blogsKeys.map((key, index) => {
            const image =
              "./assets/blogs-img" +
              "/" +
              (Object.keys(BLOGS).length - 3 + index) +
              ".jpg";
            return (
              <Box
                key={"blog_" + index}
                bg={"white"}
                rounded={"md"}
                shadow={"md"}
                overflow={"hidden"}
                p={3}
              >
                <Card maxW="sm" variant={"filled"} key={key} padding="1">
                  <CardBody>
                    <Image
                      src={image}
                      alt={BLOGS[key].title}
                      borderRadius="lg"
                    />
                    <Stack mt="6" spacing="3">
                      <Heading size="md">{BLOGS[key].title}</Heading>
                      <Text>
                        {removeTags(
                          renderToString(BLOGS[key].content).slice(0, 50)
                        )}
                        ...
                      </Text>
                    </Stack>
                  </CardBody>
                  <Divider mb={3} />
                  <CardFooter>
                    <RouterLink to={"/blog/" + key} style={{ width: "100%" }}>
                      <Button
                        variant="outline"
                        color="purple.500"
                        as="div"
                        width={"100%"}
                      >
                        {STRINGS.READ}
                      </Button>
                    </RouterLink>
                  </CardFooter>
                </Card>
              </Box>
            );
          })}
        </SimpleGrid>
        <RouterLink to={STRINGS.URL_BLOG} style={{ width: "100%" }}>
          <Button as="div" variant="link" color="purple.500" width={"100%"}>
            {STRINGS.SEE_BLOG}
          </Button>
        </RouterLink>
      </VStack>
    </Container>
  );
}
