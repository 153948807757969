import {
    FormControl,
    FormLabel,
    HStack,
    Input,
    Stack,
    Button,
    Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useResume } from "../ResumeProvider";
import ImageUpload from "../../ImageUploadButton/ImageUpload.component";
import * as STRINGS from "../../../resources/strings";
import ImproveAboutMeUseCase from "../../../domain/ImproveAboutMeUseCase";
import SaveEmailUseCase from "../../../domain/SaveEmailUseCase";
import { useCookies } from "react-cookie";

const About = () => {
    const [cookies] = useCookies([STRINGS.COOKIE_NAME]);
    const { about, setAbout } = useResume();
    const [isImprove, setIsImprove] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            new SaveEmailUseCase()
                .saveEmailUseCase(cookies[STRINGS.COOKIE_NAME], value)
                .then((res) => {})
                .catch((e) => console.log("e", e));
        }
        setAbout({ ...about, [name]: value });
    };

    const onImprove = () => {
        setIsImprove(true);
        new ImproveAboutMeUseCase()
            .improveAboutMeUseCase(about.aboutme)
            .then((res) => {
                setAbout({ ...about, aboutme: res });
                setIsImprove(false);
            })
            .catch((err) => {
                console.log("Erro", err);
                setIsImprove(true);
            });
    };

    return (
        <>
            <Stack spacing={4} mb={2}>
                {about.picture ? (
                    <Button
                        onClick={() => {
                            setAbout({ ...about, picture: "" });
                        }}
                        colorScheme="red"
                        variant="outline"
                    >
                        Remove Image
                    </Button>
                ) : (
                    <ImageUpload />
                )}

                <HStack spacing={6}>
                    <FormControl>
                        <FormLabel htmlFor="name">Full Name</FormLabel>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name="name"
                            id="name"
                            type="text"
                            variant="filled"
                            placeholder="Full Name"
                            value={about.name}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="role">Role</FormLabel>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name="role"
                            id="role"
                            type="text"
                            variant="filled"
                            placeholder="Role"
                            value={about.role}
                        />
                    </FormControl>
                </HStack>

                <HStack spacing={6}>
                    <FormControl>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name="email"
                            value={about.email}
                            id="email"
                            type="email"
                            variant="filled"
                            placeholder="Email"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="phone">Phone</FormLabel>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name="phone"
                            value={about.phone}
                            id="phone"
                            type="tel"
                            variant="filled"
                            placeholder="Phone"
                        />
                    </FormControl>
                </HStack>

                <HStack spacing={6}>
                    <FormControl>
                        <FormLabel htmlFor="address">Address</FormLabel>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name="address"
                            value={about.address}
                            id="address"
                            type="text"
                            variant="filled"
                            placeholder="Address"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="linkedin">LinkedIn</FormLabel>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name="linkedin"
                            value={about.linkedin}
                            id="linkedin"
                            type="url"
                            variant="filled"
                            placeholder="https://linkedin.com"
                        />
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl mt={3}>
                        <FormLabel htmlFor={STRINGS.BUILD_ABOUT_ME_DESCRIPTION}>
                            {STRINGS.BUILD_ABOUT_ME_DESCRIPTION}
                        </FormLabel>

                        <Textarea
                            //value={work.description}
                            onChange={(e) => handleChange(e)}
                            name="aboutme"
                            value={about.aboutme}
                            id="description"
                            variant="filled"
                            placeholder={STRINGS.BUILD_ABOUT_ME_DESCRIPTION}
                        />
                        <Stack direction="row" spacing={4}>
                            <Button
                                isLoading={isImprove}
                                colorScheme="purple"
                                variant="link"
                                loadingText={STRINGS.IMPROVING}
                                size={"xs"}
                                onClick={() => onImprove()}
                            >
                                {STRINGS.IMPROVE}
                            </Button>
                        </Stack>
                    </FormControl>
                </HStack>
            </Stack>
        </>
    );
};

export default About;
