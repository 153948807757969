import React, { useRef, useState } from "react";
import * as STRINGS from "../../resources/strings";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";

import MYImage from "../../Assets/svg/profilling.svg";

import { useCookies } from "react-cookie";
import { AiOutlineFileImage } from "react-icons/ai";

import RoastMyResumeUseCase from "../../domain/RoastMyResumeUseCase";
import UploadResumeUseCase from "../../domain/UploadResumeUseCase";
import SaveFeedbackUtilityUseCase from "../../domain/SaveFeedbackUtilityUseCase copy";

const RoastResumeComponent = () => {
  const MAX_FREE_CHARACTERS = 650;
  const submitRef = useRef(null);

  const [cookies] = useCookies([STRINGS.COOKIE_NAME]);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeFileName, setIsChangeFileName] = useState("Add your Resume");

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setImageFile(file);
      scrollToSubmit(submitRef);
      setIsChangeFileName("Change your resume");
    } else {
      setImagePreview(null);
      setImageFile(null);
      setIsChangeFileName("Add your Resume");
    }
    setFeedback(null);
  };

  const handleButtonClick = () => {
    // Open a new webpage when the button is clicked
    //window.open("https://www.example.com", "_blank");
    window.open(process.env.REACT_APP_STRIPE_OFFER_FEEDBACK, "_blanck");
  };

  const scrollToSubmit = (ref) => {
    //ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleButtonSubmit = () => {
    if (isLoading === false) {
      setIsLoading(true);
      new UploadResumeUseCase()
        .uploadResume(imageFile)
        .then((res) => {
          const img_url = res;
          return new RoastMyResumeUseCase().roastMyResumeUseCase(img_url);
        })
        .then((res) => {
          setFeedback(res);
          window.location.href = "#feedback-result";
        })
        .catch((e) => console.error(e))
        .finally((res) => setIsLoading(false));
    }
  };

  const [alert, setAlert] = React.useState({
    show: false,
    title: "",
    description: "",
    status: "success",
  });

  const splitString = (inputString, endpoint) => {
    const registeredUser = cookies[STRINGS.COOKIE_NAME];
    const feedbackPaid = cookies[STRINGS.COOKIE_FEEDBACK];
    const feedbackSession = cookies[STRINGS.COOKIE_FEEDBACK_SESSION];

    if (feedbackSession !== undefined || feedbackSession !== null) {
      new SaveFeedbackUtilityUseCase()
        .saveFeedbackUtilityUseCase(feedbackSession)
        .then((res) => {})
        .catch((e) => {});
    }

    if (registeredUser !== undefined || feedbackPaid === "true") {
      return [inputString, ""];
    }
    if (inputString.length <= MAX_FREE_CHARACTERS) {
      // If the input string is already 50 characters or less, no need to split
      return [inputString, ""];
    }

    // Find the index of the first occurrence of the endpoint after the first 50 characters
    const endpointIndex = inputString.indexOf(endpoint, MAX_FREE_CHARACTERS);

    if (endpointIndex !== -1) {
      // If the endpoint is found after the first 50 characters
      return [
        inputString.slice(0, endpointIndex + endpoint.length),
        inputString.slice(endpointIndex + endpoint.length),
      ];
    } else {
      // If the endpoint is not found, split at the 50th character
      return [
        inputString.slice(0, MAX_FREE_CHARACTERS),
        inputString.slice(MAX_FREE_CHARACTERS),
      ];
    }
  };

  return (
    <Box>
      <Container maxW={"7xl"} px={8} my={3}>
        {/* File Input with Preview and Text Box */}
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={imagePreview === null ? "center" : "none"}
        >
          {/* File Input with Preview */}
          <Box
            flex="1" // Take up 100% width on small screens
            height={{ base: "50%", md: "100%" }}
            m={2}
            style={{ textAlign: "center" }}
            bg={"white"}
            rounded={"md"}
            shadow={"md"}
            overflow={"hidden"}
            p={3}
          >
            <Text fontSize="lg" fontWeight="bold">
              Upload your Resume
            </Text>
            <Text mb={6} textAlign="center" fontSize={{ base: "sm", md: "sm" }}>
              Please note: When updating your resume, avoid uploading documents
              containing personal photos or sensitive data. We prioritize the
              privacy and security of your information.
            </Text>

            {/* Add your other UI elements here, e.g., submit button, etc. */}
            <Stack
              style={{ padding: "20px" }}
              ref={submitRef}
              hidden={imagePreview === null || feedback !== null}
            >
              <Button
                colorScheme="purple"
                size="lg"
                variant={"outline"}
                onClick={() => handleButtonSubmit()}
                isLoading={isLoading}
                hidden={isLoading}
              >
                Submit
              </Button>
              <Box style={{ textAlign: "center" }}>
                <Text
                  color={"gray.600"}
                  lineHeight={1.7}
                  fontSize="xs"
                  style={{ fontFamily: "Poppins" }}
                >
                  {STRINGS.PRIVACY_POLICY_FEEDBACK} {STRINGS.TOS}
                  {" and "}
                  {STRINGS.PRIVACY}
                </Text>
              </Box>
            </Stack>

            {/* Image Preview */}
            {imagePreview && (
              <Image src={imagePreview} alt="Preview" w="100%" />
            )}

            <Divider m={2}></Divider>
            <HStack textAlign={"right"} id="upload-resume" hidden={isLoading}>
              <Text fontSize="md" fontWeight="bold">
                {isChangeFileName}
              </Text>
              <label htmlFor="file-input">
                <Input
                  type="file"
                  id="file-input"
                  display="none"
                  accept=".png, .jpg"
                  onChange={handleImageChange}
                  mb={{ base: 4, md: 0 }}
                />
                <IconButton
                  as="span"
                  icon={<AiOutlineFileImage />}
                  aria-label="Upload Image"
                  variant="outline"
                  colorScheme="purple"
                />
              </label>
            </HStack>
          </Box>
          <Box
            flex="1" // Take up 100% width on small screens
            height={{ base: "50%", md: "100%" }}
            hidden={feedback !== null}
            p={10}
          >
            <Box textAlign="center" mb={5} hidden={!isLoading}>
              <Text
                fontSize="lg"
                fontWeight="bold"
                mb={4}
                css={{
                  "@keyframes fadeIn": {
                    from: { opacity: 0 },
                    to: { opacity: 1 },
                  },
                  animation: "fadeIn 1s ease-in-out",
                }}
              >
                Analyzing your resume and drawing insights, please wait.
              </Text>
              <Spinner size="xl" color="purple.500" />
            </Box>
            <Image
              alt={"Resume Feedback - ResumeBuilderAI"}
              fit={"contain"}
              align={"center"}
              w={"100%"}
              h={"300px"}
              m={"20px"}
              src={MYImage}
              draggable="false"
            />
          </Box>
          <Box
            flex="1" // Take up 100% width on small screens
            height={{ base: "50%", md: "100%" }}
            hidden={feedback === null}
            p={10}
            id={"feedback-result"}
          >
            <Text fontSize="lg" fontWeight="bold">
              My feedback is:
            </Text>

            <div style={{ whiteSpace: "pre-wrap" }}>
              <Text id="additional-info-text">
                {feedback && splitString(feedback, ".")[0]}
              </Text>
            </div>

            {/* Button to Open Webpage */}
            <Box
              style={{ textAlign: "center" }}
              hidden={
                feedback === null || splitString(feedback, ".")[1].length === 0
              }
            >
              <Button
                colorScheme="purple"
                size="lg"
                variant="link"
                mt={4}
                onClick={handleButtonClick}
              >
                Continue reading? Click here 🚀
              </Button>
            </Box>

            <div
              style={{ whiteSpace: "pre-wrap" }}
              hidden={
                feedback === null || splitString(feedback, ".")[1].length === 0
              }
            >
              <Text
                style={{
                  filter: "blur(4px)",
                  userSelect: "none",
                }}
              >
                {feedback && splitString(feedback, ".")[1]}
              </Text>
            </div>
          </Box>
        </Flex>

        {alert.show && (
          <Alert status={alert.status}>
            <AlertIcon />
            <AlertTitle>{alert.title}</AlertTitle>
            <AlertDescription>{alert.description}</AlertDescription>
          </Alert>
        )}
      </Container>
    </Box>
  );
};

export default RoastResumeComponent;
