import { Box, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import NewOrderUseCase from "../domain/NewOrderUseCase";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import InitPurchaseCategory, {
  InitPurchaseInitCompleted,
} from "../resources/googleAnalytics/events/InitPurchaseEvent";
import * as STRINGS from "../resources/strings";
import SendEmailUseCase from "../domain/SendEmailUseCase";
//import ReactPixel from "react-facebook-pixel";

const CreateOrderPage = ({ isMega }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const session = queryParams.get("session");
  const [cookies, setCookie] = useCookies([STRINGS.COOKIE_NAME]);

  useEffect(() => {
    if (cookies[STRINGS.COOKIE_USER] === undefined) {
      setCookie(STRINGS.COOKIE_USER, isMega);
    }

    const timer = setTimeout(() => {
      new NewOrderUseCase()
        .newOrderUseCase(session)
        .then((key) => {
          setCookie(STRINGS.COOKIE_NAME, key);
          //window.location = STRINGS.URL_BUILDER;
          window.location = STRINGS.URL_SIGN_UP;
        })
        .catch((e) => {
          console.log("Error", e);
          //window.location = STRINGS.URL_HOME;
        });

      TrackGoogleAnalyticsEvent(
        InitPurchaseCategory,
        InitPurchaseInitCompleted,
        window.location.pathname
      );
      //ReactPixel.trackCustom("new-order", "init");
    }, 0);

    return () => {
      // 👇️ clear timeout when component unmounts
      clearTimeout(timer);
    };
  });

  return (
    <Box height={"100vh"} p="50">
      <VStack>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="purple.500"
          size="xl"
        />

        <Heading
          fontSize={{ base: "3xl", md: "5xl" }}
          bgGradient="linear(to-r, purple.500, blue.600)"
          bgClip="text"
        >
          {STRINGS.NEW_ORDER_LOADING_TITLE}
        </Heading>
        <Text fontSize="lg" color={"gray.600"}>
          {STRINGS.NEW_ORDER_LOADING_DESCRIPTION}
        </Text>
      </VStack>
    </Box>
  );
};

export default CreateOrderPage;
