import FirebaseDataprovider from "../infraestructure/Firebase";

class DoLogOutUseCase {
    async doLogOutUseCase() {
        return FirebaseDataprovider.doSignOut()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default DoLogOutUseCase;
