import FirebaseDataprovider from "../infraestructure/Firebase/index";
class SaveResumeUseCase {
    async saveResumeUseCase(userId, resume) {
        return FirebaseDataprovider.resumes()
            .child(userId)
            .update(resume)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default SaveResumeUseCase;
