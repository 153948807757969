import OpenAiDataProvider from "../infraestructure/OpenAi";

class ImproveWorkDescriptionUseCase {
  async improveWorkDescriptionUseCase(work) {
    const prompt = `Write a work description based on these notes, please the output is plain text just the description. Notes:\n\nRole:${work.position}\nCompany:${work.company}\nDescription:${work.description}.`;
    return OpenAiDataProvider.completionText(prompt)
      .then((result) => {
        return result.trim();
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default ImproveWorkDescriptionUseCase;
