import { Flex, Box, Heading, Text, Container } from "@chakra-ui/react";
import { FaUpload, FaMoneyBill, FaComment, FaClock } from "react-icons/fa";

import * as STRINGS from "../../resources/strings";

const HowToUseRoast = () => {
  const columns = [
    {
      icon: <FaUpload size={30} />,
      title: "1. Upload your resume",
      description:
        "Simply upload your resume in PNG or JPG format. Our AI-powered tool will handle the rest.",
    },
    {
      icon: <FaClock size={30} />,
      title: "2. Wait for the AI to make the review",
      description:
        "Our cutting-edge AI algorithms will scan your resume and analyze its content, structure, and overall effectiveness.",
    },
    {
      icon: <FaComment size={30} />,
      title: "3. See the results",
      description:
        "Receive a comprehensive review of your resume, highlighting areas for improvement, suggesting enhancements, and providing actionable insights to help you craft a winning resume.",
    },
  ];

  return (
    <Container maxW="7xl" backgroundColor={"purple.100"} pb={2} pt={2}>
      <Heading as="h2" mb={0} textAlign="center" color={"purple.500"}>
        How it works Resume Feedback?
      </Heading>

      <Flex
        justify="space-around"
        mt={5}
        mb={5}
        direction={{ base: "column", md: "row" }}
        alignContent={"center"}
        w={"100%"}
      >
        {columns.map((column, index) => (
          <Box key={index} flex={1} textAlign="center" p={5}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              color={"purple.600"}
            >
              {column.icon}
            </Box>

            <Heading mt={4} fontSize="xl">
              {column.title}
            </Heading>
            <Text mt={2} color="gray.600">
              {column.description}
            </Text>
          </Box>
        ))}
      </Flex>
    </Container>
  );
};

export default HowToUseRoast;
