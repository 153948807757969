import CreateUserAuthUseCase from "./user/CreateUserAuthUseCase";
import CreateUserRealTimeDatabaseUseCase from "./user/CreateUserRealTimeDatabaseUseCase";

class CreateUserUseCase {
    async doCreateUserUseCase(email, pass, order, isMega) {
        var resultUser = await new CreateUserAuthUseCase()
            .doCreateUserAuthUseCase(email, pass)
            .then((res) => {
                return res;
            })
            .catch((e) => {
                throw Error(e.message.replace("Firebase:", ""));
            });

        if (resultUser !== undefined) {
            return new CreateUserRealTimeDatabaseUseCase()
                .doCreateUserRealTimeDatabaseUseCase(
                    resultUser.user.uid,
                    email,
                    order,
                    isMega
                )
                .then((result) => {
                    return result;
                })
                .catch((e) => {
                    throw Error("Internal Server Error");
                });
        }
    }
}

export default CreateUserUseCase;
