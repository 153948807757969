import FirebaseDataprovider from "../infraestructure/Firebase/index";
class SaveContactUseCase {
    async saveContactUseCase(message) {
        return FirebaseDataprovider.contacts()
            .push(message)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default SaveContactUseCase;
