import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    VStack,
    Text,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { MdDelete } from "react-icons/md";
import React, { useState } from "react";
import { useResume } from "../ResumeProvider";

const Education = () => {
    const { educationList, setEducationList, emptyEducation } = useResume();
    const [index, setIndex] = useState(0);

    const addMore = () => {
        setEducationList([...educationList, emptyEducation]);
        setIndex(educationList.length);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedEducation = educationList.map((edu, i) =>
            index === i
                ? Object.assign(edu, { [name]: value, id: uuidv4() })
                : edu
        );

        setEducationList(updatedEducation);
    };

    const deleteEducation = (id) => {
        setEducationList(educationList.filter((elem) => elem.id !== id));
        setIndex((lastIndex) => {
            if (lastIndex === 0) {
                return 0;
            }
            return lastIndex - 1;
        });
    };

    return (
        <>
            <Accordion allowToggle defaultIndex={[0]} index={index}>
                {educationList.map((education, index) => (
                    <AccordionItem key={index}>
                        <h2>
                            <AccordionButton onClick={() => setIndex(index)}>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight={"medium"}>
                                        {education.degree
                                            ? education.degree
                                            : "Degree"}
                                    </Text>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <VStack spacing={4}>
                                <Input
                                    onChange={(e) => handleChange(e, index)}
                                    name="degree"
                                    type="text"
                                    variant="filled"
                                    placeholder="Degree"
                                    value={education.degree}
                                />
                                <Input
                                    onChange={(e) => handleChange(e, index)}
                                    name="school"
                                    type="text"
                                    variant="filled"
                                    placeholder="School"
                                    value={education.school}
                                />
                            </VStack>

                            <HStack spacing={4} mt={4}>
                                <FormControl>
                                    <FormLabel htmlFor="startyr">
                                        Start Year
                                    </FormLabel>
                                    <Input
                                        onChange={(e) => handleChange(e, index)}
                                        name="startYr"
                                        id="startyr"
                                        type="number"
                                        variant="filled"
                                        min="1900"
                                        max="2030"
                                        placeholder="Start Year"
                                        value={education.startYr}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel htmlFor="endyr">
                                        End Year
                                    </FormLabel>
                                    <Input
                                        onChange={(e) => handleChange(e, index)}
                                        name="endYr"
                                        id="endyr"
                                        type="number"
                                        variant="filled"
                                        min="1900"
                                        max="2050"
                                        placeholder="End Year"
                                        value={education.endYr}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel htmlFor="grade">Grade</FormLabel>
                                    <Input
                                        onChange={(e) => handleChange(e, index)}
                                        name="grade"
                                        id="grade"
                                        type="text"
                                        variant="filled"
                                        placeholder="Grade"
                                        value={education.grade}
                                    />
                                </FormControl>
                            </HStack>
                            <HStack paddingTop={5}>
                                <Button
                                    rightIcon={<MdDelete />}
                                    onClick={() =>
                                        deleteEducation(education.id)
                                    }
                                    colorScheme={"red"}
                                >
                                    Delete
                                </Button>
                            </HStack>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>

            <Button colorScheme={"purple"} my={5} onClick={addMore}>
                Add More
            </Button>
        </>
    );
};

export default Education;
