import {
  Text,
  Button,
  Image,
  Heading,
  Stack,
  Flex,
  Box,
  Container,
  Icon,
} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import hero from "../../Assets/svg/hero.svg";
import ExistsOrderUseCase from "../../domain/ExistsOrderUseCase";
import { TrackGoogleAnalyticsEvent } from "../../resources/googleAnalytics";
import InitPurchaseCategory, {
  InitPurchaseInitHeader,
} from "../../resources/googleAnalytics/events/InitPurchaseEvent";

import * as STRINGS from "../../resources/strings";
import AvatarsRow from "../AvatarsRow/AvatarsRow";
import { FaStar } from "react-icons/fa";
const HeaderRoast = () => {
  const [cookies, setCookie, removeCookie] = useCookies([STRINGS.COOKIE_NAME]);

  const isSessionRegister = () => {
    //console.log("Cookie", cookies[STRINGS.COOKIE_NAME]);
    new ExistsOrderUseCase()
      .existsOrderUseCase(cookies[STRINGS.COOKIE_NAME])
      .then((res) => {
        if (res) {
          return (window.location = STRINGS.URL_BUILDER);
        } else {
          //return (window.location = STRINGS.STRIPE_URL);
        }
        TrackGoogleAnalyticsEvent(
          InitPurchaseCategory,
          InitPurchaseInitHeader,
          window.location.pathname
        );
      })
      .catch((e) => console.log("e", e));
  };

  return (
    <>
      <Container maxW="7xl" as="main">
        <Stack
          py={{ base: 8, md: 20 }}
          spacing={{ base: 4, md: 10 }}
          direction={{ base: "column", md: "row" }}
          align="center"
        >
          <Stack flex={1} direction={"column"} spacing={4} textAlign={"center"}>
            <Heading
              as="h1"
              fontSize={{ base: "3xl", md: "5xl" }}
              bgGradient="linear(to-r, purple.500, blue.600)"
              bgClip="text"
            >
              <Text>
                {STRINGS.HEADER_TITLE_ROAST}
                <Box
                  as="span"
                  style={{ position: "relative", whiteSpace: "nowrap" }}
                  ml={3}
                >
                  <Box
                    as="span"
                    bgGradient="linear(to-r, blue.500, purple.400)"
                    style={{
                      position: "absolute",
                      left: "-2px",
                      top: "-1px",
                      bottom: "-1px",
                      right: "-2px",
                      transform: "rotate(-1deg)",
                      marginLeft: "-3px",
                      marginTop: "0px",
                      marginBottom: "0px",
                      marginRight: "-3px",
                    }}
                  ></Box>
                  <Box
                    as="span"
                    style={{
                      position: "relative",
                      color: "white",
                    }}
                  >
                    in seconds
                  </Box>
                </Box>
              </Text>
            </Heading>

            <Text
              color={"gray.600"}
              lineHeight={1.7}
              style={{ fontFamily: "Poppins" }}
            >
              {STRINGS.HEADER_DESCRIPTION_ROAST}
            </Text>

            <Box
              //spacing={{ base: 4, sm: 6 }}
              //direction={{ base: "column", sm: "row" }}
              textAlign={"center"}
            >
              <Button
                as={"a"}
                href={"#roast-resume-section"}
                rounded={"md"}
                size={"lg"}
                px={6}
                colorScheme={"purple"}
                onClick={() => {
                  //isSessionRegister();
                }}
              >
                {STRINGS.BUILD_ROAST}
              </Button>
              <Box pl={10} mt={4} textAlign={"center"}>
                <Flex align="center">
                  {Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <Icon
                        key={index}
                        as={FaStar}
                        color="yellow.300"
                        boxSize={4}
                      />
                    ))}
                  <Text
                    ml={1}
                    color={"gray.600"}
                    lineHeight={1.7}
                    style={{ fontFamily: "Poppins" }}
                    fontSize="small"
                  >
                    +350 Resume Reviews
                  </Text>
                </Flex>
              </Box>
            </Box>
          </Stack>

          <Flex flex={1} display={["none", "block"]}>
            <Box
              position={"relative"}
              height={"300px"}
              width={"full"}
              overflow={"hidden"}
            >
              <Image
                alt={"Roast my resume"}
                fit={"contain"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={hero}
                draggable="false"
              />
            </Box>
          </Flex>
        </Stack>
      </Container>
    </>
  );
};

export default HeaderRoast;
