import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";

import BlogCategory from "../resources/googleAnalytics/events/BlogEvent";

//import ReactPixel from "react-facebook-pixel";
import { Box, Image, Spacer, Text } from "@chakra-ui/react";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";

import { useParams, useNavigate } from "react-router-dom";
import BLOGS from "../resources/blogs/blogs";
import { Helmet } from "react-helmet";

import * as STRINGS from "../resources/strings";
import RecommendationBanner from "../Components/RecomendationBanner/RecomendationBanner";

export default function BlogPost() {
    //ReactPixel.trackCustom("blog", "init");
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        TrackGoogleAnalyticsEvent(BlogCategory, id, window.location.pathname);
    }, [id]);

    const blog = BLOGS[id];

    const getBlogIndex = () => {
        var index = 0;
        Object.keys(BLOGS).map((key, i) => {
            if (key === id) {
                index = i;
            }
            return key;
        });
        return index;
    };

    const removeHTMLTags = (reactContent) => {
        const content = ReactDOMServer.renderToString(reactContent);
        if (content !== undefined || content !== null) {
            var htmlTagsRegex = /<[^>]*>/g;

            // Replace HTML tags with an empty string
            var stringWithoutHtml = content.replace(htmlTagsRegex, "");
            return stringWithoutHtml;
        } else {
            return "";
        }
    };

    if (blog === undefined) {
        return (
            <>
                <Box style={{ padding: "30px" }}>
                    <Text fontSize="2xl" as={"b"}>
                        Blog Not Found
                    </Text>
                </Box>
            </>
        );
    } else {
        return (
            <>
                <Helmet>
                    <title>
                        {STRINGS.TITLE_WEB_BLOG + " | " + BLOGS[id].title}
                    </title>
                    <link
                        rel="canonical"
                        href={STRINGS.URL_BASE + STRINGS.URL_BLOG + "/" + id}
                    />
                    <meta
                        name="description"
                        content={removeHTMLTags(blog.content).substring(0, 100)}
                    />
                    {BLOGS[id].keywords && (
                        <meta name="keywords" content={BLOGS[id].keywords} />
                    )}
                    <meta
                        property="og:title"
                        content={
                            STRINGS.TITLE_WEB_BLOG + " | " + BLOGS[id].title
                        }
                    />
                    <meta
                        property="og:description"
                        content={removeHTMLTags(blog.content).substring(0, 100)}
                    ></meta>

                    <meta
                        property="og:url"
                        content={"https://resumebuilderai.com/blog/" + id}
                    />
                </Helmet>
                <Box style={{ padding: "50px" }} position="relative">
                    <Box
                        display="flex"
                        justifyContent="center"
                        marginBottom="10"
                        position="relative"
                    >
                        <Image
                            src={`../assets/blogs-img/${getBlogIndex()}.jpg`}
                            alt={BLOGS[id].title}
                            maxW="100%"
                            objectFit="cover"
                            borderRadius="lg"
                        />
                        <Box
                            position="absolute"
                            top="90%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            textAlign="center"
                            color="white" // You can adjust the text color based on your image
                            width="100%"
                            bg={"gray.400"}
                        >
                            <header>
                                <Text fontSize="3xl" as="h1">
                                    {blog.title}
                                </Text>
                            </header>
                        </Box>
                    </Box>

                    {blog.content}
                    <Spacer pt={10}></Spacer>
                    <RecommendationBanner
                        to={STRINGS.URL_ROAST}
                    ></RecommendationBanner>
                </Box>
            </>
        );
    }
}
