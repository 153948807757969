import React, { useEffect } from "react";
import * as STRINGS from "../resources/strings";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Container,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";

import SaveAffiliateUseCase from "../domain/SaveAffiliateUseCase";
import MYImage from "../Assets/svg/revenue.svg";
import SendEmailUseCase from "../domain/SendEmailUseCase";
import { Helmet } from "react-helmet";

const AffiliatesPage = () => {
  const [subject, setSubject] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    show: false,
    title: "",
    description: "",
    status: "success",
  });

  const saveAffiliate = () => {
    if (email === "" || subject === "" || message === "") {
      setAlert({
        show: true,
        title: STRINGS.AFFILIATES_ERROR_TITLE,
        description: STRINGS.AFFILIATES_ERROR_DESCRIPTION,
        status: "error",
      });
    } else {
      const data = {
        socialNetwork: subject,
        about: message,
        email: email,
      };
      setIsLoading(true);
      new SaveAffiliateUseCase()
        .saveAffiliateUseCase(data)
        .then(() => {
          new SendEmailUseCase()
            .sendEmailAffiliate(email, subject, message)
            .then()
            .catch()
            .finally(() => {
              setAlert({
                show: true,
                title: STRINGS.AFFILIATES_SUCCESS_TITLE,
                description: STRINGS.AFFILIATES_SUCCESS_DESCRIPTION,
                status: "success",
              });
              setSubject("");
              setMessage("");
              setEmail("");
            });
        })
        .catch(() => {
          setAlert({
            show: true,
            title: STRINGS.SERVER_ERROR_TITLE,
            description: STRINGS.SERVER_ERROR_DESCRIPTION,
            status: "success",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box>
      <Helmet>
        <title>{STRINGS.TITLE_WEB_HOME}</title>
        <link
          rel="canonical"
          href={STRINGS.URL_BASE + STRINGS.URL_AFFILIATES}
        />
      </Helmet>
      <Container maxW={"7xl"} px={8} my={3}>
        <Box
          bg={"white"}
          rounded={"md"}
          shadow={"md"}
          overflow={"hidden"}
          p={3}
          mb={5}
        >
          <Stack>
            <Image
              alt={"Resume Builder AI"}
              fit={"contain"}
              align={"center"}
              w={"100%"}
              h={"300px"}
              m={"20px"}
              src={MYImage}
              draggable="false"
            />
          </Stack>
          <Stack spacing={3}>
            <Text fontSize="2xl" as={"b"}>
              {STRINGS.AFFILIATES_TITLE}
            </Text>
            <Text fontSize="m">{STRINGS.AFFILIATES_DESCRIPTION}</Text>
            <Input
              value={email}
              placeholder={STRINGS.AFFILIATES_EMAIL}
              variant="outline"
              onChange={(event) => setEmail(event.target.value)}
            />
            <Input
              value={subject}
              placeholder={STRINGS.AFFILIATES_REDES}
              variant="outline"
              onChange={(event) => setSubject(event.target.value)}
            />
            <Textarea
              value={message}
              variant="outline"
              placeholder={STRINGS.AFFILIATES_MESSAGE}
              onChange={(event) => setMessage(event.target.value)}
            />
            <Button onClick={() => saveAffiliate()} isLoading={isLoading}>
              {STRINGS.AFFILIATES_SEND}
            </Button>
          </Stack>
        </Box>
        {alert.show && (
          <Alert status={alert.status}>
            <AlertIcon />
            <AlertTitle>{alert.title}</AlertTitle>
            <AlertDescription>{alert.description}</AlertDescription>
          </Alert>
        )}
      </Container>
    </Box>
  );
};

export default AffiliatesPage;
