import { Box, Heading, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import NotFoundCategory, {
    NotFoundView,
} from "../resources/googleAnalytics/events/NotFoundEvent";
import * as STRINGS from "../resources/strings";

const PageNotFound = () => {
    useEffect(() => {
        TrackGoogleAnalyticsEvent(
            NotFoundCategory,
            NotFoundView,
            window.location.pathname
        );
    }, []);

    return (
        <Box height={"100vh"} p="50">
            <VStack>
                <img
                    alt="page not found"
                    style={{ maxHeight: "200px" }}
                    src="https://i.imgur.com/A040Lxr.png"
                />
                <Heading
                    fontSize={{ base: "3xl", md: "5xl" }}
                    bgGradient="linear(to-r, purple.500, blue.600)"
                    bgClip="text"
                >
                    {STRINGS.PAGE_NOT_FOUND_TITLE}
                </Heading>

                <Link to="/">Go Home</Link>
            </VStack>
        </Box>
    );
};

export default PageNotFound;
