import React, { useEffect } from "react";
import * as STRINGS from "../resources/strings";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Container,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { useCookies } from "react-cookie";
import ExistsOrderUseCase from "../domain/ExistsOrderUseCase";
//import ReactPixel from "react-facebook-pixel";
import {
  ContactCategory,
  NonUserContact,
  UserContact,
} from "../resources/googleAnalytics/events/ContactEvent";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import SaveContactUseCase from "../domain/SaveContactUseCase";
import MYImage from "../Assets/svg/contact.svg";
import SendEmailUseCase from "../domain/SendEmailUseCase";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  const [cookies] = useCookies([STRINGS.COOKIE_NAME]);
  const [subject, setSubject] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [alert, setAlert] = React.useState({
    show: false,
    title: "",
    description: "",
    status: "success",
  });

  useEffect(() => {
    new ExistsOrderUseCase()
      .existsOrderUseCase(cookies[STRINGS.COOKIE_NAME])
      .then((res) => {
        if (res) {
          TrackGoogleAnalyticsEvent(
            ContactCategory,
            UserContact,
            window.location.pathname
          );
        } else {
          TrackGoogleAnalyticsEvent(
            ContactCategory,
            NonUserContact,
            window.location.pathname
          );
        }
      })
      .catch((e) => console.log("e", e));

    //ReactPixel.trackCustom("contact", "init");
  }, [cookies]);

  const saveContact = () => {
    if (email === "" || subject === "" || message === "") {
      setAlert({
        show: true,
        title: STRINGS.CONTACT_ERROR_TITLE,
        description: STRINGS.CONTACT_ERROR_DESCRIPTION,
        status: "error",
      });
    } else {
      const alert_msg = {
        userId:
          cookies[STRINGS.COOKIE_NAME] === undefined
            ? ""
            : cookies[STRINGS.COOKIE_NAME],
        subject: subject,
        message: message,
        email: email,
      };
      new SaveContactUseCase()
        .saveContactUseCase(alert_msg)
        .then(() => {
          new SendEmailUseCase()
            .sendEmailContact(email, subject, message)
            .then()
            .catch()
            .finally(() => {
              setAlert({
                show: true,
                title: STRINGS.CONTACT_SUCCESS_TITLE,
                description: STRINGS.CONTACT_SUCCESS_DESCRIPTION,
                status: "success",
              });
              setSubject("");
              setMessage("");
              setEmail("");
            });
        })
        .catch(() => {
          setAlert({
            show: true,
            title: STRINGS.SERVER_ERROR_TITLE,
            description: STRINGS.SERVER_ERROR_DESCRIPTION,
            status: "error",
          });
        });
    }
  };

  return (
    <Box>
      <Helmet>
        <title>{STRINGS.TITLE_WEB_HOME}</title>
        <link rel="canonical" href={STRINGS.URL_BASE + STRINGS.URL_CONTACT} />
      </Helmet>
      <Container maxW={"7xl"} px={8} my={3}>
        <Box
          bg={"white"}
          rounded={"md"}
          shadow={"md"}
          overflow={"hidden"}
          p={3}
          mb={5}
        >
          <Stack>
            <Image
              alt={"Resume Builder AI"}
              fit={"contain"}
              align={"center"}
              w={"100%"}
              h={"300px"}
              m={"20px"}
              src={MYImage}
              draggable="false"
            />
          </Stack>
          <Stack spacing={3}>
            <Text fontSize="2xl" as={"b"}>
              {STRINGS.CONTACT_TITLE}
            </Text>
            <Text fontSize="m">{STRINGS.CONTACT_DESCRIPTION}</Text>
            <Input
              value={email}
              placeholder={STRINGS.CONTACT_EMAIL}
              variant="outline"
              onChange={(event) => setEmail(event.target.value)}
            />
            <Input
              value={subject}
              placeholder={STRINGS.CONTACT_ASSUNTO}
              variant="outline"
              onChange={(event) => setSubject(event.target.value)}
            />
            <Textarea
              value={message}
              variant="outline"
              placeholder={STRINGS.CONTACT_MESSAGE}
              onChange={(event) => setMessage(event.target.value)}
            />
            <Button onClick={() => saveContact()}>
              {STRINGS.CONTACT_SEND}
            </Button>
          </Stack>
        </Box>
        {alert.show && (
          <Alert status={alert.status}>
            <AlertIcon />
            <AlertTitle>{alert.title}</AlertTitle>
            <AlertDescription>{alert.description}</AlertDescription>
          </Alert>
        )}
      </Container>
    </Box>
  );
};

export default ContactPage;
