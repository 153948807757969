export const WEB_SITE_NAME = "ResumeBuilderAI";
export const GOOGLE_PLAY_STORE = "https://www.google.es";
export const COLOR = "#805AD5";
export const SUPPORT_EMAIL = "yourresumebuilderai@gmail.com";
export const COOKIE_NAME = WEB_SITE_NAME + "_COOKIE";
export const COOKIE_CODE = WEB_SITE_NAME + "_CODE";
export const COOKIE_USER = "MEGA_COOKIE";
export const COOKIE_FEEDBACK = "FEEDBACK_COOKIE";
export const COOKIE_FEEDBACK_SESSION = "FEEDBACK_COOKIE_SESSION";
export const APP_NAME = "ResumeBuilderAI";

export const URL_BASE = "https://resumebuilderai.com";
export const URL_BUILDER = "/cv-builder";
export const URL_HOME = "/";
export const URL_SIGN_IN = "/sign-in";
export const URL_BLOG = "/blog";
export const URL_BLOG_ID = "/blog/:id";
export const URL_SIGN_UP = "/create-user";
export const URL_ROAST = "/resume-feedback";
export const URL_ROAST_REVIEW = "/resume-review";
export const URL_AFFILIATES = "/affiliates-program";
export const URL_SIGN_UP_LOGIN = "/login-link";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_TERMS = "/terms-and-conditions";
export const URL_PRIVACY = "/privacy-policy";
export const URL_CONTACT = "/contact";

//TITLES WEB
export const TITLE_WEB_HOME = "Craft your Resume in minutes | ResumeBuilderAI";
export const TITLE_REVIEW_WEB_HOME =
    "Review your resume in seconds | ResumeBuilderAI";
export const TITLE_WEB_BLOG = "ResumeBuilderAI - Blog";
export const DESCRIPTION_WEB =
    "Receive instant feedback to fine-tune your resume document within seconds. Start now to boost your chances of landing your dream job, all while saving time.";
export const DESCRIPTION_REVIEW_WEB =
    "Tired of job application radio silence? Gain personalized insights to optimize your CV, stand out, and land those coveted interviews.";

//STRINGS
export const LOGOUT = "Logout";
export const HELLO_ACCOUNT = "Welcome again, ";
export const AFFILIATES = "Affiliates";
export const RESUME_FEEDBACK = "Resume Feedback";

//SIGNIN
export const PRICING_BUTTON = "Pricing";
export const SIGN_IN = "Log in";
export const FORGOT_SUCCESS =
    "Success, you will receive an email if the user exists ";
export const FORGOT_ERROR = "Error: You must to write an email";

//SIGNUP
export const SIGN_UP = "Sign Up";
export const SIGN_UP_ERROR = "Error: First Name or Last Name are incorrect";
export const SIGN_UP_TC = "Error: You must to accept the terms and conditions";
export const ACCEPT_TERMS = "I agree to the ";

//NEW-ORDER

export const NEW_ORDER_LOADING_TITLE = "Thank you!";
export const NEW_ORDER_LOADING_DESCRIPTION = ""; //"Your payment is been processing";
export const NEW_ORDER_FEEDBACK_LOADING_DESCRIPTION =
    "We are redirecting again to create your resume feedback";

export const PAGE_NOT_FOUND_TITLE = "Not found";

export const BUILD_RESUME = "Build Resume";
export const START_NOW = "Land Interviews Now";
export const YOU = "You";
export const HEADER_TITLE =
    "Land Interviews Faster: Elevate Your Resume in Minutes";
export const HEADER_DESCRIPTION =
    "Receive instant feedback to fine-tune your resume document within seconds. Start now to boost your chances of landing your dream job, all while saving time and money.";

export const VIDEO_ALERT = "Generic Resumes Get Ignored";
export const VIDEO_ARROW = "Solve it";
export const VIDEO_TITLE = "A poor resume can get you";
export const VIDEO_TITLE_2 = "out of the game";
export const VIDEO_DESCRIPTION =
    "A study by Jobvite found that 75% of recruiters spend 6 seconds or less reviewing a resume. This limited time means that generic resumes, which lack tailored content and fail to highlight the specific skills and experiences required for each role, are often quickly discarded.";

export const LANDING_HEADER_REVIEW =
    "This has helped me a ton! It is a extremely helpful tool and really easy to use. Using this, I was contacted by nearly half of all the possitions that I applied for!";
export const LANDING_HEADER_REVIEW_AUTHOR = "María P.";

export const LANDING_BENEFITS_TITLE = "In less than 3 minutes";
export const LANDING_BENEFITS_DESCRIPTION =
    "You will have a Resume + Cover Letter + Preparation for an interview for your dream job. Our tool is prepared focused in be detected by Applicant Tracking Systems scanners(ATS)";

export const LANDING_IMPROVE_QUALITY = "Quality";
export const LANDING_IMPROVE_HIRABLE = "Hirable Resume";
export const LANDING_IMPROVE_FRIENDLY = "Friendly";
export const LANDING_IMPROVE_POWERFUL = "Powerful";

export const LANDING_IMPROVE_QUALITY_DESCRIPTION =
    "High quality resume with the right words to draw attention";
export const LANDING_IMPROVE_HIRABLE_DESCRIPTION =
    "Create a resume to get the interview";
export const LANDING_IMPROVE_FRIENDLY_DESCRIPTION =
    "Correct resume with the keywords to hook the interviewer";
export const LANDING_IMPROVE_POWERFUL_DESCRIPTION =
    "Differentiate yourself from the competition and get the interview";

export const LANDING_PRICING_TITLE = "Let's do it";

export const PERSONAL_INFO = "Personal information";
export const NAME = "Name";
export const SURNAME = "Surname";
export const EMAIL = "Email";
export const PHONE = "Phone Number";
export const ADDRESS = "Address";
export const SUMMARY = "About me";
export const UPLOAD_PHOTO = "Upload Profile Picture";
export const PROFESSION = "Profesión";
export const CHALLENGES = "Challenges";
export const HOBBIES = "Hobbies";
export const SKILLS = "Skills";
export const ABOUT_ME = "About me";
export const EXPERIENCE = "Experience";
export const EDUCATION = "Education";

export const BUY_BUTTON = "Create an Amazing Resume";

export const SELECT_DATE = "Select Dates";
export const SAVE = "Save";
export const CANCEL = "Cancel";

export const DATES = "Dates";
export const COMPANY = "Company";
export const ROLE = "Role";
export const SUMMARY_ROLE = "Summary Role";
export const SCHOOL = "School";
export const DEGREE = "Degree";
export const DESCRIPTION = "Description";

export const PRIVACY_POLICY = "By using this site you agree to the";
export const PRIVACY_POLICY_FEEDBACK = "By submitting this you agree to the";

export const TOS = <a href="/terms-and-conditions">TOS</a>;
export const PRIVACY = <a href="/privacy-policy">PRIVACY POLICY</a>;

export const TESTIMONIAL_TITLE = "What are they think?";

export const RESUME_ABOUT_ME = "ABOUT ME";
export const RESUME_WORK_EXPERIENCE = "WORK EXPERIENCE";
export const RESUME_EDUCATION = "EDUCATION";
export const RESUME_PROJECTS = "PROJECTS";
export const RESUME_SKILLS = "SKILLS";
export const RESUME_LANGUAGES = "LANGUAGES";

export const BUILD_ABOUT_ME_DESCRIPTION = "About me";

export const BUILD_ADD_LANGUAGES = "Add Languages";
export const BUILD_LANGUAGE = "Language";
export const BUILD_LANGUAGE_LEVEL = "Proficiency";
export const SELECT_PROFICIENCY = "Select Proficiency";
export const LEVEL_NATIVE = "Native";
export const LEVEL_HIGH = "Full Professional";
export const LEVEL_MEDIUM = "Professional Working";
export const LEVEL_LOW = "Limited working";
export const LEVEL_ELEMENTARY = "Elementary";
export const BUILD_NO_LANGUAGE_ADDED = "No Language Added";

export const BUILD_ADD_SKILLS = "Add Skills";
export const BUILD_SKILL = "Skill";
export const BUILD_NO_SKILL_ADDED = "No Skills Added";
export const ADD = "Add";

export const DOWNLOAD = "Download";
export const IMPROVE = "Improve";
export const IMPROVING = "Improving";

//BLOG
export const BLOG_TITLE = "Latest posts";
export const BLOG = "Blog";
export const SEE_BLOG = "See all posts";
export const READ = "Read";

export const SUGGESTION_BANNER_TITLE = "Unlock the full potential! 🚀";
export const SUGGESTION_BANNER_DESCRIPTION =
    "Try our powerful tool today and landing an interview as soon as possible";

export const SUGGESTION_BANNER_BUTTON = "Try now";

//STRIPE
export const STRIPE_URL = process.env.REACT_APP_STRIPE_OFFER2;

//AFFILIATES
export const AFFILIATES_TITLE =
    "Join our Affiliate Program and turn your network into a revenue stream!";
export const AFFILIATES_DESCRIPTION =
    "When you partner with us, you'll enjoy a 20% commission for yourself, while also giving your referred clients an exclusive 5% discount. Whether you're a marketer, blogger, or influencer, our program offers a hassle-free way to monetize your audience while helping businesses thrive. Start earning today by joining forces with us!";
export const AFFILIATES_EMAIL = "Your Email";
export const AFFILIATES_REDES =
    "Social Networks, blogs, etc. ie: Instagram Profile, Twitter...";
export const AFFILIATES_MESSAGE = "Give us a short information about you.";
export const AFFILIATES_SEND = "Apply";

export const AFFILIATES_ERROR_TITLE = "Error";
export const AFFILIATES_ERROR_DESCRIPTION =
    "There is an error in the form, please fill it before to apply";
export const AFFILIATES_SUCCESS_TITLE = "Message was sent";
export const AFFILIATES_SUCCESS_DESCRIPTION =
    "Thank you to send your application! We are going to response you as soon as possible";

//CONTACT
export const CONTACT_TITLE = "Contact";
export const CONTACT_DESCRIPTION =
    "Can I help you? Please, let us know what are your frustactions, feedback or whatever you need. We will respond you as soon as possible";
export const CONTACT_EMAIL = "Your Email";
export const CONTACT_ASSUNTO = "Subject";
export const CONTACT_MESSAGE = "Message";
export const CONTACT_SEND = "Send";

export const CONTACT_ERROR_TITLE = "Error";
export const CONTACT_ERROR_DESCRIPTION =
    "There is an error in the form, please fill it before to send the message";
export const CONTACT_SUCCESS_TITLE = "Message was sent";
export const CONTACT_SUCCESS_DESCRIPTION =
    "Thank you to send your message! We are going to response you as soon as possible";

//Interviews
export const INTERVIEW_QUESTIONS = "Questions";
export const INTERVIEWS_TITLE = "Interview Questions & Answers";
export const INTERVIEWS_DESCRIPTION =
    "Are you struggling to come up with interview questions and answers for your next job interview? Look no further! Our tool provides you with 10 carefully crafted interview questions and answers to help you ace your next interview. \n Simply enter your Job Offer Description, and we will provide you with 10 questions and answers specific for the interview.";
export const INTERVIEWS_JOB_OFFER =
    "Please, copy and paste the Job Offer Description here";
export const INTERVIEWS_SEND = "Send";
export const INTERVIEWS_WAITING =
    "Searching the best interview questions for this role, please wait";

export const INTERVIEWS_ERROR_TITLE = "Error";
export const INTERVIEWS_ERROR_DESCRIPTION =
    "There is an error in the form, please fill it before to send the message";
export const INTERVIEWS_SUCCESS_TITLE = "Message was sent";
export const INTERVIEWS_SUCCESS_DESCRIPTION =
    "Thank you to send your message! We are going to response you as soon as possible";

//Cover Letter
export const COVERLETTER_TITLE = "Cover Letter";
export const COVERLETTER_DESCRIPTION =
    "The goal is to leverage the power of natural language processing and machine learning to help job seekers create compelling and effective cover letters that can increase their chances of getting hired. Please, attach the job offer description to get yours. ";
export const COVERLETTER_JOB_OFFER =
    "Please, copy and paste the Job Offer Description here";
export const COVERLETTER_YOUR_NAME = "Your Name";
export const COVERLETTER_COMPANY_NAME = "Company Name";
export const COVERLETTER_MANAGER_NAME = "Hiring Manager Name";
export const COVERLETTER_SEND = "Send";
export const COVERLETTER_WAITING =
    "Writing a Cover Letter for you, please wait";

export const COVERLETTER_ERROR_TITLE = "Error";
export const COVERLETTER_ERROR_DESCRIPTION =
    "There is an error in the form, please fill it before to send the message";

// ROAST RESUME
export const HEADER_TITLE_ROAST = "Get expert resume review";
export const HEADER_DESCRIPTION_ROAST =
    "Stand out from the competition and land your dream job with our comprehensive resume review tool. Our technology analyzes your resume and provides personalized feedback to help you craft a compelling document that highlights your skills and experience.";
export const BUILD_ROAST = "Get My Review Now";

//SERVER ERROR
export const SERVER_ERROR_TITLE = "Error";
export const SERVER_ERROR_DESCRIPTION =
    "Sorry, our server has a problem right now. We are trying to fix it. Please, try again in few minutes";

//DASHBOARD
export const DASHBOARD_RESUME = "Build Resume";
export const DASHBOARD_INTERVIEW = "Interview Preparation";
export const DASHBOARD_COVER_LETTER = "Cover Letter";
export const DASHBOARD_FEEDBACK = "Resume Feedback";
export const DASHBOARD_CONTACT = "Support";
