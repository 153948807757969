import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
//import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { setPersistence, browserLocalPersistence } from "firebase/auth";
import { ref, getDownloadURL } from "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class FirebaseDataProvider {
  constructor() {
    //const app = initializeApp(config);

    const initApp = app.initializeApp(config);

    this.analytics = getAnalytics(initApp);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();

    this.userLogged = undefined;

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();

    //console.log("Environment", this.getEnvironment());
  }

  getEnvironment = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // development build code
      return "-dev";
    } else {
      // production build code
      return "";
    }
  };

  doLogEvent = (event_name, data) => {
    logEvent(this.analytics, event_name, data);
  };
  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doPersistenceSignInWithEmailAndPassword = (email, password) => {
    return setPersistence(this.auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return this.auth.signInWithEmailAndPassword(email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        throw error;
      });
  };

  getAuth = () => this.auth;
  onAuthChange = (onComplete) => {
    this.auth.onAuthStateChanged((user) => onComplete(user));
  };

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignInAnonymous = () => this.auth.signInAnonymously();

  onChangedSignIn = () => this.auth.onAuthStateChanged();

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  getUserLogged = () => {
    return this.auth.currentUser;
  };

  // *** orders ***
  order = (uid) => this.db.ref(`order${this.getEnvironment()}/${uid}`);

  orders = () => this.db.ref(`order${this.getEnvironment()}`);

  // *** users ***
  user = (uid) => this.db.ref(`user${this.getEnvironment()}/${uid}`);
  users = () => this.db.ref(`user${this.getEnvironment()}`);

  // *** resume ***
  resume = (uid) => this.db.ref(`resume${this.getEnvironment()}/${uid}`);
  resumes = () => this.db.ref(`resume${this.getEnvironment()}`);

  // *** emails ***
  email = (uid) => this.db.ref(`email${this.getEnvironment()}/${uid}`);
  emails = () => this.db.ref(`email${this.getEnvironment()}`);

  // *** contacts ***
  contact = (uid) => this.db.ref(`contact${this.getEnvironment()}/${uid}`);
  contacts = () => this.db.ref(`contact${this.getEnvironment()}`);

  // *** affiliates ***
  affiliate = (uid) => this.db.ref(`affiliate${this.getEnvironment()}/${uid}`);
  affiliates = () => this.db.ref(`affiliate${this.getEnvironment()}`);

  // *** feedback ***
  feedback = (uid) => this.db.ref(`feedback${this.getEnvironment()}/${uid}`);
  feedbacks = () => this.db.ref(`feedback${this.getEnvironment()}`);

  // *** Upload resumes
  uploadResume = (imgName, img) => {
    const imageRef = this.storage.ref("roast-resumes").child(imgName);
    return imageRef.put(img);
  };

  getUrlFile = (fileName) => {
    const starsRef = ref(this.storage, "roast-resumes/" + fileName);
    return getDownloadURL(starsRef);
  };
}

const instance = new FirebaseDataProvider();
Object.freeze(instance);
export default instance;
//export default FirebaseDataProvider;
