class OpenAiDataProvider {
  constructor() {}

  completionText = async (prompt) => {
    const DEFAULT_PARAMS = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are an amazing Resume Writer. You will help to improve a Resume. Write the output in plain text to put directly on the resume template`,
        },
        {
          role: "user",
          content: prompt,
        },
      ],
    };
    const params_ = { ...DEFAULT_PARAMS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(process.env.REACT_APP_OPEN_AI_KEY),
      },
      body: JSON.stringify(params_),
    };
    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",
      requestOptions
    );
    const data = await response.json();

    return data.choices[0].message.content;
  };

  completionRoastMyResume = async (img_url, promt) => {
    const DEFAULT_PARAMS = {
      model: "gpt-4-vision-preview",
      messages: [
        {
          role: "system",
          content: `Your are a professional resume writer. Be powerfull in your opinion, and make the first sentences as hook for the reader of the review`,
        },
        {
          role: "user",
          content: [
            { type: "text", text: promt },
            {
              type: "image_url",
              image_url: img_url,
            },
          ],
        },
      ],
      max_tokens: 1500,
    };
    const params_ = { ...DEFAULT_PARAMS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(process.env.REACT_APP_OPEN_AI_KEY),
      },
      body: JSON.stringify(params_),
    };
    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",
      requestOptions
    );
    const data = await response.json();

    return data.choices[0].message.content;
  };

  completionText35 = async (prompt) => {
    const DEFAULT_PARAMS = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `Your are an interviewer and you have posted a job offer like: \n ${prompt}`,
        },
        {
          role: "user",
          content:
            "What are the 10 most important interview questions for a role like that, and write an answer of each questions",
        },
      ],
    };
    const params_ = { ...DEFAULT_PARAMS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(process.env.REACT_APP_OPEN_AI_KEY),
      },
      body: JSON.stringify(params_),
    };
    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",
      requestOptions
    );
    const data = await response.json();

    return data.choices[0].message.content;
  };

  completionText_coverletter = async (system, userValue) => {
    const DEFAULT_PARAMS = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `${system}`,
        },
        {
          role: "user",
          content: userValue,
        },
      ],
    };
    const params_ = { ...DEFAULT_PARAMS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(process.env.REACT_APP_OPEN_AI_KEY),
      },
      body: JSON.stringify(params_),
    };
    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",
      requestOptions
    );
    const data = await response.json();

    return data.choices[0].message.content;
  };
}

const instance = new OpenAiDataProvider();
Object.freeze(instance);
export default instance;
