//process.env.REACT_APP_MEASUREMENT_ID,
class BackendDataProvider {
    constructor() {}

    newAffiliate = async (email, socialNetwork, about) => {
        const DEFAULT_PARAMS = {
            email: email,
            socialNetwork: socialNetwork,
            subject: "Nuevo Affiliado -" + email,
            message:
                "Nuevo affiliado:  " +
                email +
                " \nRedes: " +
                socialNetwork +
                "\nAbout:" +
                about,
        };
        const params_ = { ...DEFAULT_PARAMS };
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params_),
        };

        const response = await fetch(
            process.env.REACT_APP_BACKEND_EMAIL,
            requestOptions
        );
        const data = await response.json();
        return data;
    };

    newBuy = async (email, version, amount) => {
        const DEFAULT_PARAMS = {
            email: email,
            version: version,
            amount: amount,
            subject:
                "Invoice for Your Recent Online Purchase - Resume Builder Ai",
            message:
                "Invoice for Your Recent Online Purchase" +
                "\nDescription: " +
                "\nResume Builder Ai - : " +
                version +
                "\n\nTotal: " +
                amount,
        };
        const params_ = { ...DEFAULT_PARAMS };
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params_),
        };

        const response = await fetch(
            process.env.REACT_APP_BACKEND_EMAIL_BUY,
            requestOptions
        );
        const data = await response.json();
        return data;
    };

    newContact = async (email, subject, message) => {
        const DEFAULT_PARAMS = {
            email: email,
            subject: "Resume Builder Nuevo Contacto",
            message:
                "Email:  " +
                email +
                "\nSubject: " +
                subject +
                "\nMessage:" +
                message,
        };
        const params_ = { ...DEFAULT_PARAMS };
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params_),
        };

        const response = await fetch(
            process.env.REACT_APP_BACKEND_EMAIL,
            requestOptions
        );
        const data = await response.json();
        return data;
    };
}

const instance = new BackendDataProvider();
Object.freeze(instance);
export default instance;
//export default FirebaseDataProvider;
