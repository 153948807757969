import OpenAiDataProvider from "../infraestructure/OpenAi";

class ImproveProjectDescriptionUseCase {
  async improveProjectDescriptionUseCase(project) {
    const prompt = `Write a project description based on these notes, please the output is plain text just the description. Notes:\n Project Name:${project.name}\nDescription:${project.description}.`;
    return OpenAiDataProvider.completionText(prompt)
      .then((result) => {
        return result.trim();
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default ImproveProjectDescriptionUseCase;
