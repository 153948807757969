import ReactGA4 from "react-ga4";
const InitializeGoogleAnalytics = () => {
    // Initialize GA4
    ReactGA4.initialize("" + process.env.REACT_APP_GA);

    //console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (category, action, label) => {
    //console.log("GA event:", category, ":", action, ":", label);
    ReactGA4.event({
        category: category,
        action: action,
        label: label,
    });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
