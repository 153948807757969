import FirebaseDataprovider from "../infraestructure/Firebase/index";
class SaveAffiliateUseCase {
    async saveAffiliateUseCase(data) {
        return FirebaseDataprovider.affiliates()
            .push(data)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default SaveAffiliateUseCase;
