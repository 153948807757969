import OpenAiDataProvider from "../infraestructure/OpenAi";

class ImproveAboutMeUseCase {
  async improveAboutMeUseCase(text) {
    const prompt = `Write an about me based on these resume, please don't mention anything about about me:\n\n${text}.`;
    return OpenAiDataProvider.completionText(prompt)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default ImproveAboutMeUseCase;
