import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
//import OpenAi from "./Components/OpenAi/OpenAi";
import CVBuilder from "./Pages/CVBuilder";
//import Landing from "./Pages/Landing";
import PageNotFound from "./Pages/PageNotFound";
import Privacy from "./Pages/Privacy";
//import Terms from "./Pages/Terms";
import WebFont from "webfontloader";
import { useEffect } from "react";
import Landing from "./Pages/Landing";
import Terms from "./Pages/Terms";
import CreateOrderPage from "./Pages/CreateOrderPage";
import InitializeGoogleAnalytics from "./resources/googleAnalytics";
import CookieConsent from "react-cookie-consent";
//import ReactPixel from "react-facebook-pixel";
import { useState } from "react";
import Blog from "./Pages/Blog";
import BlogPost from "./Pages/BlogPost";
import ContactPage from "./Pages/ContactPage";
import InterviewQuestionsPage from "./Pages/InterviewQuestionsPage";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import CoverLetterPage from "./Pages/CoverLetterPage";
import GetUserRealTimeDatabaseUseCase from "./domain/user/GetUserRealTimeDatabaseUseCase";
import GetCurrentUserUseCase from "./domain/GetCurrentUserUseCase";
import * as STRINGS from "./resources/strings";
import AffiliatesPage from "./Pages/AffiliatesPage";
import RoastResumePage from "./Pages/RoastResumePage";
import CreateOrderFeedbackPage from "./Pages/CreateOrderFeedbackResumePage";

function App() {
    const [firebaseUser, setFirebaseUser] = useState(undefined);
    useEffect(() => {
        WebFont.load({
            google: {
                families: ["Pacifico", "Poppins"],
            },
        });
        InitializeGoogleAnalytics();
        //React Pixel
        /*const advancedMatching = undefined; //{ em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: true, // enable logs
        };
        */
        //ReactPixel.init(process.env.REACT_APP_PIXEL, advancedMatching, options);
        new GetCurrentUserUseCase().getCurrentUserUseCase((user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                new GetUserRealTimeDatabaseUseCase()
                    .getUser(uid)
                    .then((res) => {
                        const user = { uid: res.key, ...res.val() };
                        setFirebaseUser(user);
                    })
                    .catch((e) => console.log("User error", e));
            } else {
                // User is signed out
                setFirebaseUser(undefined);
            }
        });
    }, []);

    return (
        <>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossorigin
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            {/*<Landing />*/}
            <Navbar currentUser={firebaseUser}></Navbar>
            <BrowserRouter>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Landing link={"/"} />}
                    ></Route>

                    {/*<Route
                        exact
                        path="/testing"
                        element={
                            <>
                                <Button
                                    onClick={() => {
                                        new TestTripUseCase()
                                            .testTripUseCase()
                                            .then((res) => {
                                                console.log("Success", res);
                                            })
                                            .catch((err) => {
                                                console.log(
                                                    "Error Project",
                                                    err
                                                );
                                            });
                                    }}
                                >
                                    Click Here
                                </Button>
                            </>
                        }
                    ></Route> 
                    */}

                    <Route
                        exact
                        path="/"
                        element={<Landing link={"/resume-builder-ai"} />}
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_SIGN_UP}
                        element={<SignUp />}
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_ROAST}
                        element={
                            <RoastResumePage canonical={STRINGS.URL_ROAST} />
                        }
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_ROAST_REVIEW}
                        element={
                            <RoastResumePage
                                canonical={STRINGS.URL_ROAST_REVIEW}
                            />
                        }
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_SIGN_IN}
                        element={<SignIn />}
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_FORGOT_PASSWORD}
                        element={<ForgotPasswordPage />}
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_TERMS}
                        element={<Terms />}
                    ></Route>
                    <Route
                        exact
                        path={STRINGS.URL_PRIVACY}
                        element={<Privacy />}
                    ></Route>
                    {/*
                    
                    <Route path="/openai" element={<OpenAi />}></Route> */}
                    <Route
                        exact
                        path={STRINGS.URL_BUILDER}
                        element={<CVBuilder></CVBuilder>}
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_CONTACT}
                        element={<ContactPage></ContactPage>}
                    ></Route>

                    <Route
                        exact
                        path={STRINGS.URL_AFFILIATES}
                        element={<AffiliatesPage></AffiliatesPage>}
                    ></Route>

                    <Route
                        exact
                        path="/new-order-feedback"
                        element={
                            <CreateOrderFeedbackPage
                                isFeedback={true}
                            ></CreateOrderFeedbackPage>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/new-order"
                        element={
                            <CreateOrderPage isMega={false}></CreateOrderPage>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/new-order-mega"
                        element={
                            <CreateOrderPage isMega={true}></CreateOrderPage>
                        }
                    ></Route>

                    <Route
                        path={STRINGS.URL_BLOG}
                        element={<Blog></Blog>}
                    ></Route>
                    <Route
                        path={STRINGS.URL_BLOG_ID}
                        element={<BlogPost></BlogPost>}
                    ></Route>

                    <Route
                        exact
                        path="/interview-questions"
                        element={
                            <InterviewQuestionsPage></InterviewQuestionsPage>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/cover-letter"
                        element={<CoverLetterPage></CoverLetterPage>}
                    ></Route>

                    <Route path="/404" element={<PageNotFound />}></Route>
                </Routes>
            </BrowserRouter>

            <Footer />

            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="ResumeBuilderAICookies"
                style={{ background: "#A335FF" }}
                buttonStyle={{ color: "#000", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience. More
                information in our{" "}
                <a href={STRINGS.URL_PRIVACY}>Privacy Policy</a> and{" "}
                <a href={STRINGS.URL_TERMS}>Terms and conditions</a>
            </CookieConsent>
        </>
    );
}

export default App;
