import { Box, Container, Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
// Components
import * as STRINGS from "../../resources/strings";
import REVIEWS from "../../resources/reviews";
import TestimonialBox from "./Elements/TestimonialBox";

export default function Testimonials() {
    return (
        <Container maxW="7xl" as="main">
            <VStack spacing={4} align="stretch">
                <Box>
                    <Heading
                        fontSize={{ base: "3xl", md: "5xl" }}
                        bgGradient="linear(to-r, purple.500, blue.600)"
                        bgClip="text"
                    >
                        {STRINGS.TESTIMONIAL_TITLE}
                    </Heading>
                </Box>
                <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={4}>
                    {REVIEWS.map((review, index) => (
                        <Box
                            key={"testimonials_" + index}
                            bg={"white"}
                            rounded={"md"}
                            shadow={"md"}
                            overflow={"hidden"}
                            p={3}
                        >
                            <TestimonialBox
                                text={review.text}
                                author={review.author}
                            />
                        </Box>
                    ))}
                </SimpleGrid>
            </VStack>
        </Container>
    );
}
