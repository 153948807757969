import FirebaseDataprovider from "../infraestructure/Firebase/index";
class SaveEmailUseCase {
    async saveEmailUseCase(userId, email) {
        return FirebaseDataprovider.emails()
            .child(userId)
            .set(email)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default SaveEmailUseCase;
