import React from "react";
// Components
import PricingTable from "./Elements/PricingTable";
import useReactIpLocation from "react-ip-details";
import getSymbolFromCurrency from "currency-symbol-map";

import * as STRINGS from "../../resources/strings";
import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Stack,
    Text,
} from "@chakra-ui/react";
import InitPurchaseCategory, {
    InitPurchaseInitPricing,
} from "../../resources/googleAnalytics/events/InitPurchaseEvent";
import { TrackGoogleAnalyticsEvent } from "../../resources/googleAnalytics";
import PRICES from "../../resources/prices.js";

export default function Pricing({ prefilled_promo_code }) {
    const { currency } = useReactIpLocation({ numberToConvert: 100 });

    var symbol = getSymbolFromCurrency(currency);

    if (symbol !== "€" && symbol !== "$") {
        symbol = "$";
    }

    return (
        <Container maxW="7xl" as="main" mt={"20px"} id={"pricing"}>
            <Stack align="stretch">
                <Box>
                    <Heading
                        fontSize={{ base: "3xl", md: "5xl" }}
                        bgGradient="linear(to-r, purple.500, blue.600)"
                        bgClip="text"
                    >
                        {STRINGS.LANDING_PRICING_TITLE}
                    </Heading>
                </Box>

                <Stack textAlign="center" id="stack">
                    <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
                        {PRICES.map((offer) => (
                            <PricingTable
                                key={offer.id}
                                icon={offer.icon}
                                background={offer.background}
                                price={offer.price + " " + symbol}
                                discount={offer.discount + " " + symbol}
                                title={offer.title}
                                text={offer.description}
                                offers={offer.offers}
                                action_title={offer.actionTitle}
                                action={() => {
                                    TrackGoogleAnalyticsEvent(
                                        InitPurchaseCategory,
                                        InitPurchaseInitPricing,
                                        window.location.pathname
                                    );
                                    if (
                                        prefilled_promo_code !== undefined &&
                                        prefilled_promo_code !== null &&
                                        offer.url.includes("stripe")
                                    ) {
                                        window.location =
                                            offer.url +
                                            "?prefilled_promo_code=" +
                                            prefilled_promo_code;
                                    } else {
                                        window.location = offer.url;
                                    }
                                }}
                            />
                        ))}
                    </SimpleGrid>

                    <Box style={{ textAlign: "center" }}>
                        <Text
                            color={"gray.600"}
                            lineHeight={1.7}
                            fontSize="sm"
                            style={{ fontFamily: "Poppins" }}
                        >
                            {STRINGS.PRIVACY_POLICY} {STRINGS.TOS}
                            {" and "}
                            {STRINGS.PRIVACY}
                        </Text>
                    </Box>
                </Stack>
            </Stack>
        </Container>
    );
}
