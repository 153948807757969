import BackendDataProvider from "../infraestructure/BackendEmail";

class SendEmailUseCase {
    async sendEmailAffiliate(email, socialNetwork, about) {
        return BackendDataProvider.newAffiliate(email, socialNetwork, about);
    }

    async sendEmailBuy(email, version, amount) {
        return BackendDataProvider.newBuy(email, version, amount);
    }

    async sendEmailContact(email, subject, message) {
        return BackendDataProvider.newContact(email, subject, message);
    }
}

export default SendEmailUseCase;
