import { Box, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import PrivacyCategory, {
  InitTerms,
} from "../resources/googleAnalytics/events/PrivacyEvent";
// Sections
//import ReactPixel from "react-facebook-pixel";
import * as STRINGS from "../resources/strings";
import { Helmet } from "react-helmet";

export default function Terms() {
  //ReactPixel.trackCustom("privacy", "init");
  useEffect(() => {
    TrackGoogleAnalyticsEvent(
      PrivacyCategory,
      InitTerms,
      window.location.pathname
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{STRINGS.TITLE_WEB_HOME}</title>
        <link rel="canonical" href={STRINGS.URL_BASE + STRINGS.URL_TERMS} />
      </Helmet>
      <Box style={{ padding: "30px" }}>
        <Text fontSize="2xl" as={"b"}>
          Terms and Conditions
        </Text>
        <Text>
          These Terms and Conditions ("Terms", "Terms and Conditions") govern
          your (the "Customer") relationship with {STRINGS.APP_NAME}
          website and apps (the "Service") operated by Jerónimo Pérez ("us",
          "we", or "our"). If a software licensing agreement of any kind has
          been signed between the Customer and Jerónimo Pérez. (
          {STRINGS.APP_NAME}) then the terms of that agreement will supersede
          any relevant clauses of the Terms and Conditions. Please read these
          Terms and Conditions carefully before using the Service. Your access
          to and use of the Service is based on your acceptance of and
          compliance with these Terms. These Terms apply to all visitors, users
          and others who access or use the Service. By accessing or using the
          Service you agree to be bound by these Terms and accept all legal
          consequences. If you do not agree to these terms and conditions, in
          whole or in part, please do not use the Service.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Purchases
        </Text>
        <Text>
          At no time will purchases of our software be made inside the Service.
          All purchases shall be made via our accounting software system. You
          represent and warrant that: (i) you have the legal right to use any
          credit card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete. We reserve the right to refuse or cancel your
          order at any time for certain reasons including but not limited to:
          product or service availability, errors in the description or price of
          the product or service, error in your order or other reasons. We
          reserve the right to refuse or cancel your order if fraud or an
          unauthorised or illegal transaction is suspected. We will not be held
          responsible or liable for any failure for the Purchase to complete, or
          any resulting loss or damages to you.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Payment Service - Stripe
        </Text>
        <Text>
          We are using Stripe that handles our financial processing, so we don't
          store any information about your card. In addition, Stripe has
          excellent bank-level security standars.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Payment Methods Accepted
        </Text>
        <Text>
          We accepted payments via credit or debit cards, MasterCard, VISA,
          American Express, iDeal, Giropay and many more payment methods. At the
          moment, we are not accepting Paypal or cryptocurrencies.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Refunds
        </Text>
        <Text>
          After your payment, you will have access to the platform to build your
          resume. If you have already been used it and agreed that, by doing so,
          you forfeit your right to withdraw.
        </Text>

        <Text fontSize="lg" as={"b"}>
          Availability, Errors and Inaccuracies
        </Text>
        <Text>
          In order to provide exceptional service, and accuracy, we regularly
          update the products and services on the Service. We cannot and do not
          guarantee the accuracy or completeness of any information, including
          prices, product images, specifications, availability, and services. We
          reserve the right to change or update information and to correct
          errors, inaccuracies, or omissions at any time without prior notice.
          Despite our best efforts, the products or services available on our
          Service may have an error, be inaccurately described, or be
          unavailable. We may experience delays in updating information on the
          Service and in our advertising on other websites.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Content
        </Text>
        <Text>
          Our Service allows you to post, link, store, share and otherwise make
          available certain information, text, graphics, videos, or other
          material ("Content"). You are responsible for the Content that you
          post to the Service and subsequent applications, including its
          legality, reliability, and appropriateness. By posting Content to the
          Service, you grant us the right and license to use, modify, publicly
          perform, publicly display, reproduce, and distribute such Content on
          and through the Service. You retain any and all of your rights to any
          Content you submit, post or display on or through the Service and you
          are responsible for protecting those rights. You agree that this
          license includes the right for us to make your Content available to
          other users of the Service, who may also use your Content subject to
          these Terms and your approval. You represent and warrant that: (i) the
          Content is yours (you own it) or you have the right to use it and
          grant us the rights and license as provided in these Terms, and (ii)
          the posting of your Content on or through the Service does not violate
          the privacy rights, publicity rights, copyrights, contract rights or
          any other rights of any person. We reserve all rights to block or
          remove communications or materials that we determine to be: (a)
          abusive, defamatory, or obscene; (b) fraudulent, deceptive, or
          misleading; (c) in violation of a copyright, trademark or, other
          intellectual property right of another or; (d) offensive or otherwise
          unacceptable to us in our sole discretion. You acknowledge that, by
          providing you with the ability to view and distribute user-generated
          content on the Service, we are merely acting as a passive conduit for
          such distribution and is not undertaking any obligation or liability
          relating to any contents or activities on the Service.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Accounts
        </Text>
        <Text>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service. You are responsible for
          safeguarding the password that you use to access the Service and for
          any activities or actions under your password, whether your password
          is with our Service or a third-party service. You agree not to
          disclose your password to any third party. You must notify us
          immediately upon becoming aware of any breach of security or
          unauthorized use of your account. You may not use as a username the
          name of another person or entity or that is not lawfully available for
          use, a name or trade mark that is subject to any rights of another
          person or entity other than you without appropriate authorization, or
          a name that is otherwise offensive, vulgar or obscene.
        </Text>

        <Text fontSize="lg" as={"b"}>
          Resume Feedback Tool
        </Text>
        <Text>
          {STRINGS.APP_NAME} provides a Resume Feedback tool that utilizes a
          third-party endpoint provided by OpenAI. By using this tool, you
          acknowledge and agree to the following terms:
        </Text>
        <Text>
          <b>1. Purpose and Restrictions:</b> The Resume Feedback tool is
          intended solely for the analysis and feedback of resumes or CVs. You
          are expressly prohibited from uploading any other type of image, and
          any such uploads will be considered a violation of these Terms and
          Conditions.
        </Text>
        <Text>
          <b>2. Confidentiality:</b> When using the Resume Feedback tool, be
          cautious not to upload resumes containing sensitive personal
          information such as phone numbers, addresses, names, or profile
          photos. You are solely responsible for the content you upload, and{" "}
          {STRINGS.APP_NAME} disclaims any liability for the misuse of such
          information.
        </Text>
        <Text>
          <b>3. Third-Party Sharing:</b> By using the Resume Feedback tool, you
          understand and consent to the sharing of resume images with OpenAI for
          the purpose of analysis and feedback. {STRINGS.APP_NAME} will take
          reasonable measures to ensure the confidentiality of your information,
          as outlined in our Privacy Policy.
        </Text>
        <Text>
          <b>4. User Responsibility:</b> You acknowledge that you are solely
          responsible for the content you upload through the Resume Feedback
          tool. {STRINGS.APP_NAME} disclaims any liability for the accuracy,
          legality, or appropriateness of the content, and reserves the right to
          block or remove any content that violates these Terms.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Intellectual Property
        </Text>
        <Text>
          The Service and all contents, including but not limited to text,
          images, graphics or code are the property of {STRINGS.APP_NAME}
          and trademarks, database and other intellectual property rights. You
          may display and copy, download or print portions of the material from
          the different areas of the Service only for your own non-commercial
          use, or to place an order with
          {STRINGS.APP_NAME}. Any other use is strictly prohibited and may
          violate copyright, trademark and other laws. These Terms do not grant
          you a license to use any trademark of {STRINGS.APP_NAME}. or its
          affiliates. You further agree not to use, change or delete any
          proprietary notices from materials downloaded from the Service. You
          agree that you will not: sublicense the Service; rent, lease, resell,
          distribute, transmit, network or create derivative works based upon
          the Service or any part of it; create a competing product or service
          to the Service or any part of it; copy the Service, in whole or in
          part, except as provided in the agreement; transfer your license;
          reverse engineer, decompile, or alter in any way, this Service.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Links To Other Web Sites
        </Text>
        <Text>
          The Service may contain links to third-party web sites or services
          that are not owned or controlled by {STRINGS.APP_NAME}. has no control
          over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third-party web sites or services. You
          further acknowledge and agree that {STRINGS.APP_NAME} shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such websites or services. We strongly advise you to read
          the terms and conditions and privacy policies of any third-party web
          sites or services that you visit.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Termination
        </Text>
        <Text>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including,
          without limitation, if you breach the Terms. All provisions of the
          Terms shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability. Upon termination, your right to use the Service will
          immediately cease. If you wish to terminate your account, you may
          simply discontinue using the Service.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Indemnification
        </Text>
        <Text>
          You agree to indemnify, defend and hold harmless {STRINGS.APP_NAME},
          its principals, officers, directors, representatives, employees,
          contractors, licensors, licensees, suppliers and agents, from and
          against any claims, losses, damages, software failure or errors,
          obligations, costs, actions or demands. These include but are not
          limited to: (a) legal and accounting fees resulting from your use of
          the Service; (b) your breach of any of these Terms; (c) anything you
          post on or upload to the Service; and (d) any activity related to your
          account, including software failure. This includes any negligent or
          illegal conduct by you, any person or entity accessing the Service
          using your account whether such access is obtained via fraudulent or
          illegal means.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Limitation Of Liability
        </Text>
        <Text>
          {STRINGS.APP_NAME}., its directors, employees, partners, agents,
          suppliers, or affiliates, shall not be liable for any loss or damage,
          direct or indirect, incidental, special, consequential or punitive
          damages, including without limitation, economic loss, loss or damage
          to electronic media or data, goodwill, or other intangible losses,
          resulting from (i) your access to or use of the Service; (ii) your
          inability to access or use the Service; (iii) any conduct or content
          of any third-party on or related to the Service; (iiv) any content
          obtained from or through the Service; and (v) the unauthorized access
          to, use of or alteration of your transmissions or content, whether
          based on warranty, contract, tort (including negligence) or any other
          claim in law, whether or not we have been informed of the possibility
          of such damage, and even if a remedy set forth herein is found to have
          failed of its essential purpose.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Disclaimer And Non-Waiver of Rights
        </Text>
        <Text>
          {STRINGS.APP_NAME}. makes no guarantees, representations or warranties
          of any kind as regards the website and associated technology. Any
          purportedly applicable warranties, terms and conditions are excluded,
          to the fullest extent permitted by law. Your use of the Service is at
          your sole risk. The Service is provided on an "AS IS" and "AS
          AVAILABLE" basis. The Service is provided without warranties of any
          kind, whether express or implied, including, but not limited to,
          implied warranties of merchantability, fitness for a particular
          purpose, non-infringement or course of performance, except as provided
          for under the laws of any province in Canada. In such cases, the
          provincial law shall apply to the extent necessary. {STRINGS.APP_NAME}
          . its subsidiaries, affiliates, and its licensors do not warrant that
          a) the Service will function uninterrupted, secure or available at any
          particular time or location; b) any errors or defects will be
          corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements. If you breach any of these Terms and {STRINGS.APP_NAME}.
          chooses not to immediately act, or chooses not to act at all,{" "}
          {STRINGS.APP_NAME} will still be entitled to all rights and remedies
          at any later date, or in any other situation, where you breach these
          Terms. {STRINGS.APP_NAME}. does not waive any of its rights.{" "}
          {STRINGS.APP_NAME}. shall not be responsible for any purported breach
          of these Terms caused by circumstances beyond its control. A person
          who is not a party to these Terms shall have no rights of enforcement.
          You may not assign, sub-license or otherwise transfer any of your
          rights under these Terms.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Exclusions
        </Text>
        <Text>
          As set out, above, some jurisdictions do not allow the exclusion of
          certain warranties or the exclusion or limitation of liability for
          consequential or incidental damages, so the limitations above may not
          apply to you. Provincial laws of Portugal may apply to certain
          products and service provided.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Governing Law
        </Text>
        <Text>
          These Terms shall be governed by, and interpreted and enforced in
          accordance with, the laws in the Province of Lisbon and the laws of
          Portugal, as applicable. If any provision of these Terms is held to be
          invalid or unenforceable by a court of competent jurisdiction, then
          any remaining provisions of these Terms will remain in effect. These
          Terms constitute the entire agreement between us regarding our
          Service, and supersede and replace any prior agreements, oral or
          otherwise, regarding the Service.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Changes
        </Text>
        <Text>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will make
          reasonable efforts to provide at least 30 days' notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at our sole discretion. By continuing to access or use our
          Service after those revisions become effective, you agree to be bound
          by the revised terms. If you do not agree to the new terms, in whole
          or in part, please stop using the website and the Service.
        </Text>
        <Text fontSize="lg" as={"b"}>
          Contact
        </Text>
        <Text>
          If you have any questions about these Terms, please contact us.{" "}
          {STRINGS.SUPPORT_EMAIL}
        </Text>

        <Text mt={5}>Updated at: 4 / December / 2024</Text>
      </Box>
    </>
  );
}
