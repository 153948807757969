import FirebaseDataprovider from "../infraestructure/Firebase/index";
class SaveFeedbackUtilityUseCase {
  async saveFeedbackUtilityUseCase(session) {
    const date = new Date();
    return FirebaseDataprovider.feedbacks()
      .child(session)
      .update({ updatedAt: date })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default SaveFeedbackUtilityUseCase;
