import FirebaseDataprovider from "../infraestructure/Firebase";

class ForgotPasswordUseCase {
    async doForgotPassword(email) {
        return FirebaseDataprovider.doPasswordReset(email)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default ForgotPasswordUseCase;
