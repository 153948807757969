import FirebaseDataprovider from "../../infraestructure/Firebase";

class CreateUserRealTimeDatabaseUseCase {
    async doCreateUserRealTimeDatabaseUseCase(uid, email, order, isMega) {
        var newUser = {
            email: email,
            order: order,
            isMega: isMega,
            createdAt: new Date().toISOString(),
        };

        return FirebaseDataprovider.users()
            .child(uid)
            .set(newUser)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default CreateUserRealTimeDatabaseUseCase;
