import {
    Box,
    Heading,
    HStack,
    Tag,
    TagLabel,
    Text,
    VStack,
    Wrap,
    UnorderedList,
    ListItem,
    Stack,
} from "@chakra-ui/react";
import { useResume } from "../ResumeBuilder/ResumeProvider";
import { MdMail, MdLocalPhone, MdLocationPin } from "react-icons/md";
import { RiLinkedinBoxFill } from "react-icons/ri";
import { BiLinkExternal } from "react-icons/bi";

import * as STRINGS from "../../resources/strings";

const ResumePreview = () => {
    const {
        theme,
        about,
        educationList,
        skills,
        languages,
        workList,
        projects,
        printElem,
    } = useResume();

    const imgStyle = {
        width: "115px",
        height: "115px",
        margin: "15px",
        objectFit: "cover",
        borderRadius: "50%",
    };

    return (
        <>
            <Box
                bg={"white"}
                w={"full"}
                rounded={"md"}
                shadow={"md"}
                overflow={"hidden"}
                minH={"100vh"}
            >
                <div ref={printElem}>
                    <HStack>
                        {about.picture && (
                            <img
                                style={imgStyle}
                                src={about.picture}
                                alt="avatar"
                            />
                        )}

                        <VStack m={4} alignItems={"flex-start"} spacing={0.5}>
                            <Heading as="h4" size="md">
                                {about.name ? about.name : "Jhon Doe"}
                            </Heading>
                            <Text color={"gray.500"}>
                                {about.role
                                    ? about.role
                                    : "Full Stack Web Developer"}
                            </Text>
                        </VStack>
                    </HStack>

                    <HStack
                        bg={theme}
                        color={"white"}
                        p={4}
                        justifyContent={"space-between"}
                    >
                        <HStack spacing={1}>
                            <MdMail />{" "}
                            <Text>
                                {about.email
                                    ? about.email
                                    : "jhone@resumebuilderai.com"}
                            </Text>
                        </HStack>
                        <HStack spacing={1}>
                            <MdLocalPhone />{" "}
                            <Text>
                                {about.phone
                                    ? about.phone
                                    : "+1 (650) XXX-XXXX"}
                            </Text>
                        </HStack>
                        <HStack spacing={1}>
                            <MdLocationPin />{" "}
                            <Text>
                                {about.address
                                    ? about.address
                                    : "San Francisco, CA"}
                            </Text>
                        </HStack>
                        <HStack spacing={1}>
                            <RiLinkedinBoxFill />{" "}
                            <Text as="a" href={about.linkedin}>
                                LinkedIn
                            </Text>
                        </HStack>
                    </HStack>

                    <Stack
                        w={"full"}
                        h={"full"}
                        my={2}
                        mb={6}
                        px={2}
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        spacing={3}
                    >
                        <Stack>
                            <Heading as="h4" size="md" color={"gray.700"}>
                                {STRINGS.RESUME_ABOUT_ME}
                            </Heading>
                            <Text fontSize={"sm"} as="p">
                                {about.aboutme
                                    ? about.aboutme
                                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
                            </Text>
                        </Stack>
                        <Stack>
                            {
                                //WORK EXPERIENCE}
                            }
                            <Heading as="h4" size="md" color={"gray.700"}>
                                {STRINGS.RESUME_WORK_EXPERIENCE}
                            </Heading>
                            {workList !== undefined &&
                                workList.map((work, index) => {
                                    const {
                                        position,
                                        type,
                                        company,
                                        startDate,
                                        endDate,
                                        description: desc,
                                    } = work;

                                    return (
                                        <VStack
                                            key={index}
                                            spacing={0.5}
                                            alignItems={"flex-start"}
                                            lineHeight={1.3}
                                            pb={2}
                                        >
                                            <Text fontWeight={"medium"}>
                                                {position
                                                    ? position
                                                    : "Full Stack Developer"}
                                            </Text>
                                            <HStack
                                                fontSize={"xs"}
                                                fontStyle={"italic"}
                                                justifyContent={"space-between"}
                                                w={"full"}
                                            >
                                                <Text fontSize={"sm"}>
                                                    {company
                                                        ? company
                                                        : "XYZ Infotech Services"}{" "}
                                                    -{" "}
                                                    {type ? type : "Full-time"}
                                                </Text>
                                                <Text
                                                    fontSize={"xs"}
                                                    fontStyle={"italic"}
                                                >
                                                    {startDate
                                                        ? startDate
                                                        : "2018-03"}{" "}
                                                    -{" "}
                                                    {endDate
                                                        ? endDate
                                                        : "Today"}
                                                </Text>
                                            </HStack>
                                            <Text fontSize={"sm"} as="p">
                                                {desc
                                                    ? desc
                                                    : "Sed auctor turpis sit amet ligula tristique, in porta mi consequat. In justo nibh, commodo sed pulvinar ac, ullamcorper vel ligula. Pellentesque elementum sem dui, mollis suscipit lectus luctus eu. Integer id laoreet nunc, ac facilisis diam. Vestibulum convallis commodo erat vitae fringilla. Morbi aliquet felis eget ligula laoreet euismod."}
                                            </Text>
                                        </VStack>
                                    );
                                })}
                        </Stack>

                        <Stack>
                            {
                                //EDUCATION
                            }
                            <Heading as="h4" size="md" color={"gray.700"}>
                                {STRINGS.RESUME_EDUCATION}
                            </Heading>
                            {educationList !== undefined &&
                                educationList.map((education, index) => {
                                    const {
                                        degree,
                                        school,
                                        startYr,
                                        endYr,
                                        grade,
                                    } = education;

                                    return (
                                        <VStack
                                            key={index}
                                            spacing={0}
                                            alignItems={"flex-start"}
                                            w={"full"}
                                            pb={2}
                                        >
                                            <Text fontWeight={"medium"}>
                                                {degree
                                                    ? degree
                                                    : "B.Tech Computer Engineering"}
                                            </Text>
                                            <Text fontSize={"sm"}>
                                                {school
                                                    ? school
                                                    : "University of Southern California, LA"}
                                            </Text>
                                            <HStack
                                                fontSize={"xs"}
                                                fontStyle={"italic"}
                                                justifyContent={"space-between"}
                                                w={"full"}
                                            >
                                                <Text>
                                                    {startYr ? startYr : 2014} -{" "}
                                                    {endYr ? endYr : 2018}
                                                </Text>
                                                <Text>
                                                    {grade ? grade : "8.7 CGPA"}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    );
                                })}
                        </Stack>

                        <Stack>
                            {projects.length > 0 && (
                                <Heading as="h4" size="md" color={"gray.700"}>
                                    {STRINGS.RESUME_PROJECTS}
                                </Heading>
                            )}

                            {projects.map((project, index) => {
                                const {
                                    name,
                                    url,
                                    description: desc,
                                } = project;
                                return (
                                    <VStack
                                        key={index}
                                        spacing={0.5}
                                        alignItems={"flex-start"}
                                        lineHeight={1.3}
                                        pb={2}
                                    >
                                        <HStack
                                            as="a"
                                            href={url}
                                            target="_blank"
                                            spacing={0.5}
                                        >
                                            <Text
                                                fontWeight={"medium"}
                                                flex={"row"}
                                            >
                                                {name ? name : "Project Name"}{" "}
                                            </Text>{" "}
                                            <BiLinkExternal />
                                        </HStack>
                                        <UnorderedList pl={5}>
                                            <ListItem>
                                                <Text fontSize={"sm"} as="p">
                                                    {desc
                                                        ? desc
                                                        : "Lorem ipsum dolor sit amet consectetur adipisicing."}
                                                </Text>
                                            </ListItem>
                                        </UnorderedList>
                                    </VStack>
                                );
                            })}
                        </Stack>

                        <Stack>
                            <Heading as="h4" size="md" color={"gray.700"}>
                                {STRINGS.RESUME_SKILLS}
                            </Heading>
                            <Wrap>
                                {skills.map((skill, index) => (
                                    <Tag
                                        size={"md"}
                                        borderRadius="md"
                                        variant="solid"
                                        bg={theme.replace("400", "500")}
                                        key={index}
                                    >
                                        <TagLabel>{skill.name}</TagLabel>
                                    </Tag>
                                ))}
                            </Wrap>
                        </Stack>

                        <Stack>
                            <Heading as="h4" size="md" color={"gray.700"}>
                                {STRINGS.RESUME_LANGUAGES}
                            </Heading>
                            <Wrap>
                                {languages.map((skill, index) => (
                                    <Tag
                                        size={"md"}
                                        borderRadius="md"
                                        variant="solid"
                                        bg={theme.replace("400", "500")}
                                        key={index}
                                    >
                                        <TagLabel>
                                            {skill.name} ({skill.proficiency})
                                        </TagLabel>
                                    </Tag>
                                ))}
                            </Wrap>
                        </Stack>
                    </Stack>
                </div>
            </Box>
        </>
    );
};

export default ResumePreview;
