import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import DoLoginUseCase from "../domain/DoLoginUseCase";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import * as STRINGS from "../resources/strings";
import GetUserRealTimeDatabaseUseCase from "../domain/user/GetUserRealTimeDatabaseUseCase";
import SignInCategory, {
  OpenSignIn,
} from "../resources/googleAnalytics/events/SigninEvent";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";

export default function SignIn() {
  const toast = useToast();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    STRINGS.COOKIE_NAME,
    STRINGS.COOKIE_USER,
  ]);

  const [email, setEmail] = useState(false);

  const [pass, setPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    TrackGoogleAnalyticsEvent(
      SignInCategory,
      OpenSignIn,
      window.location.pathname
    );
  }, []);

  const signin = () => {
    setIsLoading(true);
    new DoLoginUseCase()
      .doLoginUseCase(email, pass)
      .then((res) => {
        new GetUserRealTimeDatabaseUseCase()
          .getUser(res.user.uid)
          .then((res) => {
            const user = res.val();
            setIsLoading(false);
            if (user.isMega === undefined) {
              navigate(STRINGS.URL_HOME);
            } else {
              //navigate(STRINGS.URL_CHAT);
              //SET COOKIES
              setCookie(STRINGS.COOKIE_USER, user.isMega);
              setCookie(STRINGS.COOKIE_NAME, user.order);

              window.location = STRINGS.URL_BUILDER;
              //navigate(STRINGS.URL_BUILDER);
            }
          })
          .catch((e) => {
            console.log("Error Get User ", e);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        toast({
          title: err.message,
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{STRINGS.TITLE_WEB_HOME}</title>
        <link rel="canonical" href={STRINGS.URL_BASE + STRINGS.URL_SIGN_IN} />
      </Helmet>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Sign in to your account</Heading>
            <Text fontSize={"lg"} color={"gray.600"}>
              to enjoy all of our cool <Link color={"gray.600"}>features</Link>{" "}
              ✌️
            </Text>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  onChange={(evt) => setEmail(evt.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  onChange={(evt) => setPass(evt.target.value)}
                />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  {/*<Checkbox>Remember me</Checkbox>*/}
                  <Link color={"purple.400"} href={STRINGS.URL_FORGOT_PASSWORD}>
                    Forgot password?
                  </Link>
                </Stack>
                <Button
                  bg={"purple.400"}
                  color={"white"}
                  _hover={{
                    bg: "purple.500",
                  }}
                  onClick={signin}
                  isLoading={isLoading}
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
            {/*<Stack pt={6}>
                        <Text align={"center"}>
                            Not have an account?{" "}
                            <Link color={"purple.400"} href={STRINGS.URL_SIGN_UP}>
                                Sign up
                            </Link>
                        </Text>
                    </Stack>
                            */}
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
