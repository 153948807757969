import React, { useEffect } from "react";
import * as STRINGS from "../resources/strings";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Container,
    Divider,
    Image,
    Stack,
    Text,
    Textarea,
} from "@chakra-ui/react";

import { useCookies } from "react-cookie";
import ExistsOrderUseCase from "../domain/ExistsOrderUseCase";
import InterviewQuestionsUseCase from "../domain/InterviewQuestionsUseCase";

import MYImage from "../Assets/svg/interview.svg";

const InterviewQuestionsPage = () => {
    const [cookies] = useCookies([STRINGS.COOKIE_NAME]);
    const [jobOffer, setJobOffer] = React.useState("");
    const [questions, setQuestions] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [alert, setAlert] = React.useState({
        show: false,
        title: "",
        description: "",
        status: "success",
    });

    useEffect(() => {
        new ExistsOrderUseCase()
            .existsOrderUseCase(cookies[STRINGS.COOKIE_NAME])
            .then((res) => {
                if (res) {
                } else {
                    return (window.location = STRINGS.STRIPE_URL);
                }
            })
            .catch((e) => console.log("e", e));
    }, [cookies]);

    const askToChatContact = () => {
        setIsLoading(true);
        new InterviewQuestionsUseCase()
            .interviewQuestionsUseCase(jobOffer)
            .then((res) => {
                setIsLoading(false);
                setJobOffer("");
                setQuestions(res);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    return (
        <Box>
            <Container maxW={"7xl"} px={8} my={3}>
                <Box
                    bg={"white"}
                    rounded={"md"}
                    shadow={"md"}
                    overflow={"hidden"}
                    p={3}
                    mb={5}
                >
                    <Stack>
                        <Image
                            alt={"Resume Builder AI"}
                            fit={"contain"}
                            align={"center"}
                            w={"100%"}
                            h={"300px"}
                            m={"20px"}
                            src={MYImage}
                            draggable="false"
                        />
                    </Stack>
                    <Stack spacing={3}>
                        <Text fontSize="2xl" as={"b"}>
                            {STRINGS.INTERVIEWS_TITLE}
                        </Text>
                        <Text fontSize="m">
                            {STRINGS.INTERVIEWS_DESCRIPTION}
                        </Text>

                        <Textarea
                            value={jobOffer}
                            variant="outline"
                            placeholder={STRINGS.INTERVIEWS_JOB_OFFER}
                            onChange={(event) =>
                                setJobOffer(event.target.value)
                            }
                        />
                        <Button
                            onClick={() => askToChatContact()}
                            isLoading={isLoading}
                            loadingText={STRINGS.INTERVIEWS_WAITING}
                            disabled={jobOffer.length < 100}
                            colorScheme="purple"
                            variant={"outline"}
                        >
                            {STRINGS.CONTACT_SEND}
                        </Button>
                        <Divider></Divider>
                        {questions !== "" && (
                            <Text fontSize="2xl" as={"b"}>
                                {STRINGS.INTERVIEW_QUESTIONS}
                            </Text>
                        )}
                        <div style={{ whiteSpace: "pre-wrap" }}>
                            {questions}
                        </div>
                    </Stack>
                </Box>
                {alert.show && (
                    <Alert status={alert.status}>
                        <AlertIcon />
                        <AlertTitle>{alert.title}</AlertTitle>
                        <AlertDescription>{alert.description}</AlertDescription>
                    </Alert>
                )}
            </Container>
        </Box>
    );
};

export default InterviewQuestionsPage;
