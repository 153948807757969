import OpenAiDataProvider from "../infraestructure/OpenAi";

class CoverLetterUseCase {
    async coverLetterUseCase(joboffer, yourName, companyName) {
        const system = `Your are a hiring manager that works for ${companyName} and you have posted a job offer like: \n ${joboffer}`;
        const userValue = `I need an amazing cover letter for this role, my name is ${yourName}`;
        return OpenAiDataProvider.completionText_coverletter(system, userValue)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default CoverLetterUseCase;
