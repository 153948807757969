import React, { useEffect } from "react";
import {
  Stack,
  Flex,
  Heading,
  Spacer,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  IconButton,
} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { MdDensityMedium } from "react-icons/md";
import ExistsOrderUseCase from "../../domain/ExistsOrderUseCase";
import DoLogOutUseCase from "../../domain/DoLogOutUseCase";
import * as STRINGS from "../../resources/strings";
import { MdMenu } from "react-icons/md";

const Navbar = ({ currentUser }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    STRINGS.COOKIE_USER,
    STRINGS.COOKIE_NAME,
  ]);
  const [isUserRegistered, setIsUserRegistered] = React.useState(false);
  useEffect(() => {
    new ExistsOrderUseCase()
      .existsOrderUseCase(cookies[STRINGS.COOKIE_NAME])
      .then((res) => {
        if (res) {
          setIsUserRegistered(true);
        } else {
          setIsUserRegistered(false);
        }
      })
      .catch((e) => console.log("e", e));
  }, [cookies]);

  var name = "";
  if (currentUser !== undefined) {
    name = STRINGS.HELLO_ACCOUNT;
  }

  const onClickLogOut = () => {
    new DoLogOutUseCase()
      .doLogOutUseCase()
      .then((res) => {
        window.location = STRINGS.URL_HOME;
      })
      .catch((e) => console.log("Logout Error", e));
  };

  const isMega = cookies[STRINGS.COOKIE_USER];

  var showLogin = true;

  if (currentUser !== undefined || isUserRegistered) {
    showLogin = false;
  }

  return (
    <Stack p={5} as="header">
      <Flex w="full" alignItems={"center"}>
        <Heading
          as="h3"
          ml={{ base: 0, sm: 8 }}
          size="lg"
          fontWeight={"thin"}
          color="purple.500"
          style={{ fontFamily: "Pacifico", cursor: "pointer" }}
          onClick={() => (window.location = "/")}
        >
          {STRINGS.APP_NAME}
        </Heading>
        <Spacer></Spacer>
        {showLogin === true && (
          <HStack
            spacing={10}
            mr={{ base: 8, sm: 8 }}
            as="nav"
            style={{ fontFamily: "Poppins" }}
          >
            <Flex alignItems={"center"}>
              <Menu>
                <MenuButton
                  as={Button}
                  color={"purple.500"}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                  onClick={() => {
                    window.location.replace(STRINGS.URL_HOME + "#pricing");
                  }}
                >
                  {STRINGS.PRICING_BUTTON}
                </MenuButton>
              </Menu>
            </Flex>
          </HStack>
        )}
        <HStack
          spacing={10}
          mr={{ base: 0, sm: 8 }}
          as="nav"
          style={{ fontFamily: "Poppins" }}
        >
          {!showLogin && (
            <Flex alignItems={"center"}>
              <Menu>
                <MenuButton
                  as={Button}
                  color={"purple.500"}
                  rounded={"full"}
                  variant={"outlined"}
                  cursor={"pointer"}
                  minW={0}
                  leftIcon={<MdMenu></MdMenu>}
                >
                  {"Menu"}
                </MenuButton>
                <MenuList>
                  <MenuGroup title={name}>
                    <MenuItem onClick={() => (window.location = "/cv-builder")}>
                      {STRINGS.DASHBOARD_RESUME}
                    </MenuItem>
                    <MenuItem
                      onClick={() => (window.location = STRINGS.URL_ROAST)}
                    >
                      {STRINGS.DASHBOARD_FEEDBACK}
                    </MenuItem>
                    {isMega === "true" && (
                      <>
                        <MenuItem
                          onClick={() => {
                            window.location = "/interview-questions";
                          }}
                        >
                          {STRINGS.DASHBOARD_INTERVIEW}
                        </MenuItem>
                        <MenuItem
                          onClick={() => (window.location = "/cover-letter")}
                        >
                          {STRINGS.DASHBOARD_COVER_LETTER}
                        </MenuItem>
                        <MenuItem
                          onClick={() => (window.location = "/contact")}
                        >
                          {STRINGS.DASHBOARD_CONTACT}
                        </MenuItem>
                      </>
                    )}

                    <MenuDivider />
                    <MenuItem onClick={onClickLogOut}>
                      {STRINGS.LOGOUT}
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Flex>
          )}

          {showLogin === true && (
            <Flex alignItems={"center"}>
              <Menu>
                <MenuButton
                  as={Button}
                  color={"purple.500"}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                  onClick={() => {
                    window.location.replace(STRINGS.URL_SIGN_IN);
                  }}
                >
                  {STRINGS.SIGN_IN}
                </MenuButton>
              </Menu>
            </Flex>
          )}
        </HStack>
      </Flex>
    </Stack>
  );
};

export default Navbar;

/*
<HStack
                    spacing={10}
                    mr={{ base: 0, sm: 8 }}
                    as="nav"
                    style={{ fontFamily: "Poppins" }}
                >
                   
                     {!isUserRegistered && buildResumeButton()}
                     {isUserRegistered &&
                         dashboard(cookies[STRINGS.COOKIE_USER], isMobile)}
                 </HStack>

                 */
