import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Input,
    VStack,
    Textarea,
    Text,
    Stack,
} from "@chakra-ui/react";
import { useResume } from "../ResumeProvider";
import { v4 as uuidv4 } from "uuid";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import * as STRINGS from "../../../resources/strings";
import ImproveProjectDescriptionUseCase from "../../../domain/ImproveProjectDescriptionMeUseCase";

const Projects = () => {
    const { projects, setProjects, emptyProject } = useResume();
    const [isImprove, setIsImprove] = useState(false);
    const [index, setIndex] = useState(0);

    const addMore = () => {
        setProjects([...projects, emptyProject]);
        setIndex(projects.length);
    };

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        const updatedProject = projects.map((project) =>
            project.id === id
                ? Object.assign(project, { id: uuidv4(), [name]: value })
                : project
        );
        setProjects(updatedProject);
    };

    const deleteProject = (id) => {
        setProjects(projects.filter((elem) => elem.id !== id));
        setIndex((lastIndex) => {
            if (lastIndex === 0) {
                return 0;
            }
            return lastIndex - 1;
        });
    };

    const onImprove = (id) => {
        setIsImprove(true);
        projects.map((project) => {
            if (project.id === id) {
                new ImproveProjectDescriptionUseCase()
                    .improveProjectDescriptionUseCase(project)
                    .then((res) => {
                        setIsImprove(false);
                        const updatedProjects = projects.map((project) =>
                            project.id === id
                                ? Object.assign(project, {
                                      id: uuidv4(),
                                      description: res,
                                  })
                                : project
                        );

                        setProjects(updatedProjects);
                    })
                    .catch((err) => {
                        setIsImprove(false);
                        console.log("Error Project", err);
                    });
            }
        });
    };

    return (
        <>
            <Accordion allowToggle defaultIndex={[0]} index={index}>
                {projects.map((project, index) => (
                    <AccordionItem key={index}>
                        <h2>
                            <AccordionButton onClick={() => setIndex(index)}>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight={"medium"}>
                                        {project.name
                                            ? project.name
                                            : "Project Name"}
                                    </Text>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <VStack spacing={3} alignItems={"flex-start"}>
                                <Input
                                    value={project.name}
                                    onChange={(e) =>
                                        handleChange(e, project.id)
                                    }
                                    name="name"
                                    id="name"
                                    type="text"
                                    variant="filled"
                                    placeholder="Project Name"
                                />

                                <Input
                                    value={project.url}
                                    onChange={(e) =>
                                        handleChange(e, project.id)
                                    }
                                    name="url"
                                    id="url"
                                    type="url"
                                    variant="filled"
                                    placeholder="Project URL"
                                />

                                <Textarea
                                    value={project.description}
                                    onChange={(e) =>
                                        handleChange(e, project.id)
                                    }
                                    name="description"
                                    id="description"
                                    variant="filled"
                                    placeholder="Description..."
                                />
                                <Stack direction="row" spacing={4}>
                                    <Button
                                        isLoading={isImprove}
                                        colorScheme="purple"
                                        variant="link"
                                        loadingText={STRINGS.IMPROVING}
                                        size={"xs"}
                                        onClick={() => onImprove(project.id)}
                                    >
                                        {STRINGS.IMPROVE}
                                    </Button>
                                </Stack>

                                <Button
                                    rightIcon={<MdDelete />}
                                    onClick={() => deleteProject(project.id)}
                                    colorScheme={"red"}
                                >
                                    Delete
                                </Button>
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>

            <Button colorScheme={"purple"} my={5} onClick={addMore}>
                Add More
            </Button>
        </>
    );
};

export default Projects;
