const REVIEWS = [
    {
        author: "Juliana Roma",
        text: "I highly recommend ResumeBuilderAI. It's a fantastic tool that saved me a lot of time and helped me create a professional, user-friendly resume. The automated process is easy to follow and produces great results. I'm really happy with my new resume and I know it will help me stand out from the competition. Thanks, ResumeBuilderAI!",
    },
    {
        author: "Pedro Ramirez",
        text: " Easy tool. It helped me stand out from the other candidates and get the interview",
    },
    {
        text: "Wow, ResumeBuilderAI has completely transformed my LinkedIn profile. I've seen a huge increase in views and engagement since using the tool to update my profile. It's so user-friendly and the results are impressive. I would highly recommend ResumeBuilderAI to anyone looking to improve their LinkedIn presence.",
        author: "Catarina",
    },
    {
        author: "Mariana Ribera",
        text: "Super powerful tool to create a resume that impresses",
    },
    {
        author: "Carlos Pérez",
        text: "ResumeBuilderAI was such a helpful tool in my job search. The automated process made it easy to create a professional, standout resume in just minutes. I was able to tailor my resume to each job I applied for, which helped me stand out from the other candidates. Thanks to ResumeBuilderAI, I was able to land more interviews and ultimately, the job I wanted. I would definitely recommend it to others.",
    },

    {
        author: "Juan Sancho",
        text: "I got my job thanks to creating my resume with this tool",
    },
];

export default REVIEWS;
