import {
    Button,
    FormControl,
    Flex,
    Heading,
    Input,
    Stack,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import * as STRINGS from "../resources/strings";
import ForgotPasswordUseCase from "../domain/ForgotPasswordUseCase";

export default function ForgotPasswordPage() {
    const toast = useToast();
    const [email, setEmail] = useState(undefined);

    const forgotPassword = () => {
        if (email !== undefined && email !== "") {
            new ForgotPasswordUseCase()
                .doForgotPassword(email)
                .then((res) => {
                    setEmail("");
                })
                .catch((e) => {});
            toast({
                title: STRINGS.FORGOT_SUCCESS,
                status: "success",
                isClosable: true,
            });
        } else {
            toast({
                title: STRINGS.FORGOT_ERROR,
                status: "error",
                isClosable: true,
            });
        }
    };
    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack
                spacing={4}
                w={"full"}
                maxW={"md"}
                bg={useColorModeValue("white", "gray.700")}
                rounded={"xl"}
                boxShadow={"lg"}
                p={6}
                my={12}
            >
                <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
                    Forgot your password?
                </Heading>
                <Text
                    fontSize={{ base: "sm", sm: "md" }}
                    color={useColorModeValue("gray.800", "gray.400")}
                >
                    You&apos;ll get an email with a reset link
                </Text>
                <FormControl id="email">
                    <Input
                        placeholder="your-email@example.com"
                        _placeholder={{ color: "gray.500" }}
                        type="email"
                        value={email === undefined ? "" : email}
                        onChange={(evt) => setEmail(evt.target.value)}
                    />
                </FormControl>
                <Stack spacing={6}>
                    <Button
                        bg={"purple.400"}
                        color={"white"}
                        _hover={{
                            bg: "purple.500",
                        }}
                        onClick={forgotPassword}
                    >
                        Request Reset
                    </Button>
                </Stack>
            </Stack>
        </Flex>
    );
}
