import {
  Text,
  Stack,
  Box,
  Container,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";

import * as STRINGS from "../../resources/strings";

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"7xl"}
        py={5}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Stack direction={{ base: "column", md: "row" }} fontWeight={"medium"}>
          <Text>
            &copy;{getCurrentYear()} - {STRINGS.APP_NAME}
          </Text>
        </Stack>

        <Stack direction={"row"} spacing={6}>
          <Link href={STRINGS.URL_ROAST}>{STRINGS.RESUME_FEEDBACK}</Link>
          <Link href={STRINGS.URL_AFFILIATES}>{STRINGS.AFFILIATES}</Link>
          <Link href="contact">{STRINGS.CONTACT_TITLE}</Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
