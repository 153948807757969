import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import Pricing from "../Components/Pricing/Pricing";
import Testimonials from "../Components/Testimonials/Testimonials";
import VideoPlayer from "../Components/VideoPlayer/VideoPlayer";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import LandingCategory, {
    OpenLanding,
} from "../resources/googleAnalytics/events/LandingEvent";
//import ReactPixel from "react-facebook-pixel";
import BlogCarousel from "../Components/BlogCarousel/BlogCarousel";
import { Box } from "@chakra-ui/react";
import Benefits from "../Components/Benefits/Benefits";
import { Helmet } from "react-helmet-async";
import * as STRINGS from "../resources/strings";
import { useCookies } from "react-cookie";
// Sections

export default function Landing(data) {
    const queryParams = new URLSearchParams(window.location.search);
    const promoCode = queryParams.get("code");
    const [cookies, setCookie] = useCookies([STRINGS.COOKIE_CODE]);

    useEffect(() => {
        TrackGoogleAnalyticsEvent(
            LandingCategory,
            OpenLanding,
            window.location.pathname
        );
        //ReactPixel.trackCustom("landing", "init");

        if (promoCode !== undefined && promoCode !== null) {
            setCookie(STRINGS.COOKIE_CODE, promoCode, { maxAge: 3600 * 24 });
        }
    });

    const prefilled_promo_code = cookies[STRINGS.COOKIE_CODE];
    return (
        <>
            <Helmet>
                <title>{STRINGS.TITLE_WEB_HOME}</title>
                <link
                    rel="canonical"
                    href={STRINGS.URL_BASE + STRINGS.URL_HOME}
                />
                <meta
                    name="keywords"
                    content="resume builder, resume feedback, resume review, resume builder ai, resume maker, ai-powered resume, job search, career development, resume templates, applicat tracking system, ats, job application, employment, fired, job search, ResumeBuilderAI.com, jobseekers, resume, artificial intelligence"
                />
                <meta name="description" content={STRINGS.DESCRIPTION_WEB} />
                <meta property="og:title" content={STRINGS.TITLE_WEB_HOME} />
                <meta
                    property="og:description"
                    content={STRINGS.DESCRIPTION_WEB}
                ></meta>
                <meta property="og:url" content={STRINGS.URL_BASE} />
            </Helmet>
            <Header data={data} />
            <VideoPlayer></VideoPlayer>
            <Benefits></Benefits>
            <Pricing prefilled_promo_code={prefilled_promo_code} />
            <Box p={"20px"}></Box>
            <Testimonials />
            <Box p={"20px"}></Box>
            <BlogCarousel></BlogCarousel>
        </>
    );
}
