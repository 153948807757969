import { Box, useColorModeValue } from "@chakra-ui/react";

export default function SuggestionRow({ children }) {
    return (
        <Box
            textAlign="center"
            mt={4}
            mb={4}
            p={4}
            width={"full"}
            shadow="base"
            bg={"pink.300"}
            color={"white"}
            borderWidth="1px"
            alignSelf={{ base: "center", lg: "flex-start" }}
            borderColor={useColorModeValue("gray.100", "gray.400")}
            borderRadius={"xl"}
        >
            {children}
        </Box>
    );
}

/**
 * EXAMPLE
 */
/*
<SuggestionRow
    children={
        <>
            <Link
                textDecorationLine={"underline"}
                href="https://resumebuilderai.com/blog/why-having-a-perfect-resume-is-crucial-for-your-career-success">Why Having a Perfect Resume is Crucial for Your Career Success
                </Link> 
                      
            
   </>
            }
></SuggestionRow>
*/
