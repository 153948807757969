import React, { useEffect } from "react";
import * as STRINGS from "../resources/strings";
import { Box, Heading, Text, Divider, Flex } from "@chakra-ui/react";
import RoastResumeComponent from "../Components/RoastResumeComponent/RoastResumeComponent";
import HowToUseRoast from "../Components/HowToUseRoast/HowToUseRoast";
import HeaderRoast from "../Components/Header/HeaderRoast";
import { useCookies } from "react-cookie";
import { FaBriefcase, FaChartLine, FaMoneyBillWave } from "react-icons/fa";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import ResumeFeedbackCategory, {
    InitResumeFeedback,
} from "../resources/googleAnalytics/events/ResumeFeedbackEvent";
import { Helmet } from "react-helmet";

const RoastResumePage = ({ canonical }) => {
    const [cookies] = useCookies([STRINGS.COOKIE_NAME]);

    useEffect(() => {
        TrackGoogleAnalyticsEvent(
            ResumeFeedbackCategory,
            InitResumeFeedback,
            window.location.pathname
        );
    }, []);

    const registeredUser = cookies[STRINGS.COOKIE_NAME];
    const feedbackPaid = cookies[STRINGS.COOKIE_FEEDBACK];

    const showHeader = registeredUser !== undefined || feedbackPaid === "true";

    const columns = [
        {
            icon: <FaChartLine size={30} />,
            description: "Increased interview invitations by 20%",
        },
        {
            icon: <FaMoneyBillWave size={30} />,
            description: "Received multiple job offers",
        },
        {
            icon: <FaBriefcase size={30} />,
            description: "Landed your dream job",
        },
    ];

    return (
        <Box maxW={"7xl"}>
            <Helmet>
                <title>{STRINGS.TITLE_REVIEW_WEB_HOME}</title>
                <link
                    rel="canonical"
                    href={
                        canonical
                            ? canonical
                            : STRINGS.URL_BASE + STRINGS.URL_ROAST
                    }
                />
                <meta
                    name="keywords"
                    content="resume builder, resume feedback, resume review, resume builder ai, resume maker, ai-powered resume, job search, career development, resume templates, applicat tracking system, ats, job application, employment, fired, job search, ResumeBuilderAI.com, jobseekers, resume, artificial intelligence"
                />
                <meta
                    name="description"
                    content={STRINGS.DESCRIPTION_REVIEW_WEB}
                />
                <meta
                    property="og:title"
                    content={STRINGS.TITLE_REVIEW_WEB_HOME}
                />
                <meta
                    property="og:description"
                    content={STRINGS.DESCRIPTION_REVIEW_WEB}
                ></meta>
                <meta
                    property="og:url"
                    content={STRINGS.URL_BASE + STRINGS.URL_ROAST}
                />
            </Helmet>

            {!showHeader && <HeaderRoast></HeaderRoast>}
            {!showHeader && <HowToUseRoast></HowToUseRoast>}
            <Box mt={8}>
                <Heading
                    as="h2"
                    fontSize={{ base: "3xl", md: "5xl" }}
                    bgGradient="linear(to-r, purple.500, blue.600)"
                    bgClip="text"
                    textAlign="center"
                    id={"roast-resume-section"}
                >
                    Take your resume to the next level
                </Heading>

                {/* Description */}

                <Flex
                    justify="space-around"
                    mt={5}
                    mb={5}
                    direction={{ base: "column", md: "row" }}
                    alignContent={"center"}
                    w={"100%"}
                    color={"gray.600"}
                    lineHeight={1.7}
                    style={{ fontFamily: "Poppins" }}
                    p={10}
                    pt={0}
                    textAlign={"center"}
                >
                    {columns.map((column, index) => (
                        <Box key={index} flex={1} textAlign="center" p={5}>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                color={"purple.600"}
                            >
                                {column.icon}
                            </Box>
                            <Text mt={2} color="gray.700" as={"b"}>
                                {column.description}
                            </Text>
                        </Box>
                    ))}
                </Flex>
                <Text
                    color={"gray.600"}
                    lineHeight={1.7}
                    style={{ fontFamily: "Poppins" }}
                    p={10}
                    pt={0}
                    textAlign={"center"}
                >
                    Don't wait any longer! Try our resume feedback tool today
                    and see the difference it can make in your career.
                </Text>
            </Box>
            <Divider></Divider>
            <RoastResumeComponent></RoastResumeComponent>
        </Box>
    );
};

export default RoastResumePage;
