import FirebaseDataprovider from "../infraestructure/Firebase/index";
class LoadResumeUseCase {
    async loadResumeUseCase(userId) {
        return FirebaseDataprovider.resume(userId)
            .get()
            .then((result) => {
                return result.val();
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default LoadResumeUseCase;
