import { Card, CardBody } from "@chakra-ui/card";
import { Text } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../../Assets/svg/Quotes";

export default function TestimonialBox({ text, author }) {
    return (
        <Card style={{ textAlign: "center" }}>
            <CardBody>
                <QuoteWrapper>
                    <QuoteIcon />
                </QuoteWrapper>
                <Text
                    color={"gray.600"}
                    lineHeight={1.7}
                    style={{ fontFamily: "Poppins" }}
                >
                    {text}
                </Text>
                <Text
                    color={"gray.1200"}
                    style={{ fontFamily: "Poppins" }}
                    as={"b"}
                >
                    {author}
                </Text>
            </CardBody>
        </Card>
    );
}

const QuoteWrapper = styled.div`
    position: relative;
    top: -20px;
`;

/*
<QuoteWrapper>
                <QuoteIcon />
            </QuoteWrapper>
            <p className="whiteColor font13" style={{ paddingBottom: "30px" }}>
                {text}
            </p>
            <p className="orangeColor font13" style={{ alignSelf: "flex-end" }}>
                <em>{author}</em>
            </p>*/
