import {
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Select,
    Tag,
    TagCloseButton,
    TagLabel,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useResume } from "../ResumeProvider";
import { useToast } from "@chakra-ui/react";

import * as STRINGS from "../../../resources/strings";

const Skills = () => {
    const toast = useToast();
    const [skill, setSkill] = useState("");
    const { skills, setSkills } = useResume();

    const [language, setLanguage] = useState("");
    const [proficiency, setProficiency] = useState("");
    const { languages, setLanguages } = useResume();

    const handleSubmitSkill = (e) => {
        e.preventDefault();
        if (!skill || skill === " ") {
            toast({
                title: "Empty Input",
                status: "error",
                isClosable: true,
            });
            return;
        }
        const newSkill = {
            id: uuidv4(),
            name: skill,
        };
        setSkills([...skills, newSkill]);
        setSkill("");
    };

    const deleteSkill = (id) => {
        setSkills(skills.filter((elem) => elem.id !== id));
    };

    const handleSubmitLanguage = (e) => {
        e.preventDefault();
        if (
            !language ||
            language === " " ||
            !proficiency ||
            proficiency === ""
        ) {
            toast({
                title: "Empty Input",
                status: "error",
                isClosable: true,
            });
            return;
        }
        const newLanguage = {
            id: uuidv4(),
            name: language,
            proficiency: proficiency,
        };

        setLanguages([...languages, newLanguage]);
        setProficiency("");
        setLanguage("");
    };

    const deleteLanguage = (id) => {
        setLanguages(languages.filter((elem) => elem.id !== id));
    };

    return (
        <>
            <HStack
                spacing={4}
                alignItems={"flex-end"}
                as="form"
                onSubmit={(e) => handleSubmitLanguage(e)}
            >
                <FormControl>
                    <FormLabel htmlFor="skill">
                        {STRINGS.BUILD_ADD_LANGUAGES}
                    </FormLabel>
                    <Input
                        onChange={(e) => setLanguage(e.target.value)}
                        value={language}
                        name={STRINGS.BUILD_LANGUAGE}
                        id={STRINGS.BUILD_LANGUAGE}
                        type="text"
                        variant="filled"
                        placeholder={STRINGS.BUILD_LANGUAGE}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="skill">
                        {STRINGS.BUILD_LANGUAGE_LEVEL}
                    </FormLabel>
                    <Select
                        value={proficiency}
                        onChange={(e) => setProficiency(e.target.value)}
                        name="type"
                        variant="filled"
                        placeholder={STRINGS.SELECT_PROFICIENCY}
                    >
                        <option value={STRINGS.LEVEL_NATIVE}>
                            {STRINGS.LEVEL_NATIVE}
                        </option>
                        <option value={STRINGS.LEVEL_HIGH}>
                            {STRINGS.LEVEL_HIGH}
                        </option>
                        <option value={STRINGS.LEVEL_MEDIUM}>
                            {STRINGS.LEVEL_MEDIUM}
                        </option>
                        <option value={STRINGS.LEVEL_LOW}>
                            {STRINGS.LEVEL_LOW}
                        </option>
                        <option value={STRINGS.LEVEL_ELEMENTARY}>
                            {STRINGS.LEVEL_ELEMENTARY}
                        </option>
                    </Select>
                </FormControl>
                <Button type="submit" colorScheme={"purple"}>
                    {STRINGS.ADD}
                </Button>
            </HStack>

            <Box borderWidth={"1px"} rounded={"sm"} my={4} p={2}>
                {languages.length > 0
                    ? languages.map((language, index) => (
                          <Tag
                              size={"lg"}
                              borderRadius="full"
                              variant="solid"
                              colorScheme="purple"
                              m={0.5}
                              key={language.id}
                          >
                              <TagLabel>{language.name}</TagLabel>
                              <TagCloseButton
                                  onClick={() => deleteLanguage(language.id)}
                              />
                          </Tag>
                      ))
                    : STRINGS.BUILD_NO_LANGUAGE_ADDED}
            </Box>
            <Divider></Divider>
            <HStack
                spacing={4}
                alignItems={"flex-end"}
                as="form"
                onSubmit={(e) => handleSubmitSkill(e)}
            >
                <FormControl>
                    <FormLabel htmlFor="skill">
                        {STRINGS.BUILD_ADD_SKILLS}
                    </FormLabel>
                    <Input
                        onChange={(e) => setSkill(e.target.value)}
                        value={skill}
                        name={STRINGS.BUILD_SKILL}
                        id={STRINGS.BUILD_SKILL}
                        type="text"
                        variant="filled"
                        placeholder={STRINGS.BUILD_SKILL}
                    />
                </FormControl>
                <Button type="submit" colorScheme={"purple"}>
                    {STRINGS.ADD}
                </Button>
            </HStack>

            <Box borderWidth={"1px"} rounded={"sm"} my={4} p={2}>
                {skills.length > 0
                    ? skills.map((skill, index) => (
                          <Tag
                              size={"lg"}
                              borderRadius="full"
                              variant="solid"
                              colorScheme="purple"
                              m={0.5}
                              key={skill.id}
                          >
                              <TagLabel>{skill.name}</TagLabel>
                              <TagCloseButton
                                  onClick={() => deleteSkill(skill.id)}
                              />
                          </Tag>
                      ))
                    : STRINGS.BUILD_NO_SKILL_ADDED}
            </Box>
        </>
    );
};

export default Skills;
