import {
  Box,
  Button,
  Divider,
  Heading,
  Spacer,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import * as STRINGS from "../../resources/strings";

export default function RecommendationBanner({ to = "" }) {
  return (
    <Box
      textAlign="center"
      mb={4}
      p={4}
      width={"full"}
      shadow="base"
      bg={"purple.500"}
      color={"white"}
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {STRINGS.SUGGESTION_BANNER_TITLE}
      </Heading>
      <Text>{STRINGS.SUGGESTION_BANNER_DESCRIPTION}</Text>
      <Spacer mb={10}></Spacer>
      <RouterLink to={to} style={{ width: "100%" }}>
        <Button color="purple.500" bg={"white"} size={"md"} as="div">
          {STRINGS.SUGGESTION_BANNER_BUTTON}
        </Button>
      </RouterLink>
    </Box>
  );
}
