import ALEXANDER from "../Assets/img/alexander.jpg";
import ARTHUR from "../Assets/img/arthur.jpg";
import CHRISTINA from "../Assets/img/christina.jpg";
import DAVIDE from "../Assets/img/davide.jpg";
import ITAY from "../Assets/img/itay.jpg";
import JOAN from "../Assets/img/joan.jpg";
import MICHAEL from "../Assets/img/michael.jpg";
import PETER from "../Assets/img/peter.jpg";
import TOM from "../Assets/img/tom.jpg";
import YIN from "../Assets/img/yin.jpg";

export const avatars = [
  {
    name: "Alexander Florence",
    url: ALEXANDER,
  },
  {
    name: "Arthur Adebayo",
    url: ARTHUR,
  },
  {
    name: "Christina Dodds",
    url: CHRISTINA,
  },
  {
    name: "Yin Otemuyiwa",
    url: YIN,
  },
  {
    name: "Michael Myer",
    url: MICHAEL,
  },
  {
    name: "Tom Nwamba",
    url: TOM,
  },
  {
    name: "Peter Robs",
    url: PETER,
  },

  {
    name: "Joan Alcaide",
    url: JOAN,
  },
  {
    name: "Itay Len",
    url: ITAY,
  },
  {
    name: "Davide Frandi",
    url: DAVIDE,
  },
];
