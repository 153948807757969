import {
    Text,
    Image,
    Heading,
    Stack,
    Flex,
    Box,
    Container,
} from "@chakra-ui/react";
import image from "../../Assets/svg/profilling.svg";

import * as STRINGS from "../../resources/strings";
const Benefits = ({ data }) => {
    return (
        <>
            <Container maxW="7xl" as="main">
                <Stack
                    py={{ base: 8, md: 20 }}
                    spacing={{ base: 4, md: 10 }}
                    direction={{ base: "column", md: "row" }}
                    align="center"
                >
                    <Flex flex={1}>
                        <Box
                            position={"relative"}
                            height={"400px"}
                            width={"full"}
                            overflow={"hidden"}
                        >
                            <Image
                                alt={"Resume Imagen"}
                                fit={"contain"}
                                align={"center"}
                                w={"100%"}
                                h={"100%"}
                                src={image}
                                draggable="false"
                            />
                        </Box>
                    </Flex>

                    <Stack flex={1} direction={"column"} spacing={4}>
                        <Heading
                            fontSize={{ base: "3xl", md: "5xl" }}
                            bgGradient="linear(to-r, purple.500, blue.600)"
                            bgClip="text"
                        >
                            {STRINGS.LANDING_BENEFITS_TITLE}
                        </Heading>

                        <Text
                            color={"gray.600"}
                            lineHeight={1.7}
                            style={{ fontFamily: "Poppins" }}
                        >
                            {STRINGS.LANDING_BENEFITS_DESCRIPTION}
                        </Text>
                    </Stack>
                </Stack>
            </Container>
        </>
    );
};

export default Benefits;
