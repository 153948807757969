import FirebaseDataprovider from "../infraestructure/Firebase/index";

class NewOrderUseCase {
    async newOrderUseCase(orderSession) {
        return FirebaseDataprovider.orders()
            .push(orderSession)
            .then((result) => {
                return result.key;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default NewOrderUseCase;
