import FirebaseDataprovider from "../infraestructure/Firebase/index";

class UploadResumeUseCase {
  async uploadResume(resumeImage) {
    const myImage = "cv-" + new Date().getTime();

    return FirebaseDataprovider.uploadResume(myImage, resumeImage)
      .then((result) => {
        return FirebaseDataprovider.getUrlFile(myImage);
      })
      .then((url) => {
        return url;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default UploadResumeUseCase;
