import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import BlogCategory, {
    InitBlog,
} from "../resources/googleAnalytics/events/BlogEvent";

//import ReactPixel from "react-facebook-pixel";
import {
    Box,
    Button,
    Divider,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    Text,
} from "@chakra-ui/react";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import BLOGS, { removeTags } from "../resources/blogs/blogs.js";
import * as STRINGS from "../resources/strings.js";
import { Card, CardBody, CardFooter } from "@chakra-ui/card";
import { renderToString } from "react-dom/server";
import { Helmet } from "react-helmet";
export default function Blog() {
    //ReactPixel.trackCustom("blog", "init");

    useEffect(() => {
        TrackGoogleAnalyticsEvent(
            BlogCategory,
            InitBlog,
            window.location.pathname
        );
    }, []);

    const blogsKeys = Object.keys(BLOGS).reverse();

    return (
        <>
            <Helmet>
                <title>{STRINGS.TITLE_WEB_HOME}</title>
                <link
                    rel="canonical"
                    href={STRINGS.URL_BASE + STRINGS.URL_BLOG}
                />
                <meta name="description" content={STRINGS.DESCRIPTION_WEB} />
                <meta property="og:title" content={STRINGS.TITLE_WEB_HOME} />
                <meta
                    property="og:description"
                    content={STRINGS.DESCRIPTION_WEB}
                ></meta>
                <meta
                    property="og:url"
                    content={STRINGS.URL_BASE + STRINGS.URL_BLOG}
                />
            </Helmet>
            <Box style={{ padding: "30px" }}>
                <Text fontSize="2xl" as={"b"}></Text>
                <Heading
                    as="h1"
                    fontSize={{ base: "3xl", md: "4xl" }}
                    bgGradient="linear(to-r, purple.500, blue.600)"
                    bgClip="text"
                >
                    {STRINGS.BLOG}
                </Heading>
                <Divider style={{ marginBottom: "30px" }}></Divider>
                <SimpleGrid spacing={4} columns={{ sm: 2, md: 3 }}>
                    {blogsKeys.map((key, index) => {
                        const image_index = blogsKeys.length - index - 1;

                        return (
                            <Box
                                key={"blog_" + index}
                                bg={"white"}
                                rounded={"md"}
                                shadow={"md"}
                                overflow={"hidden"}
                                p={3}
                            >
                                <Card
                                    maxW="sm"
                                    variant={"filled"}
                                    key={key}
                                    padding="1"
                                >
                                    <CardBody>
                                        <Image
                                            src={
                                                "./assets/blogs-img" +
                                                "/" +
                                                image_index +
                                                ".jpg"
                                            }
                                            alt={BLOGS[key].title}
                                            borderRadius="lg"
                                        />
                                        <Stack mt="6" spacing="3">
                                            <Heading size="md" as="h2">
                                                {BLOGS[key].title}
                                            </Heading>
                                            <Text>
                                                {removeTags(
                                                    renderToString(
                                                        BLOGS[key].content
                                                    )
                                                ).slice(0, 180)}
                                                ...
                                            </Text>
                                        </Stack>
                                    </CardBody>
                                    <Divider mb={3} />
                                    <CardFooter>
                                        <RouterLink
                                            to={"/blog/" + key}
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                as="div"
                                                variant="outline"
                                                color="purple.500"
                                                /*onClick={() => {
                        window.location = "/blog/" + key;
                      }}*/
                                                width={"100%"}
                                            >
                                                {STRINGS.READ}
                                            </Button>
                                        </RouterLink>
                                    </CardFooter>
                                </Card>
                            </Box>
                        );
                    })}
                </SimpleGrid>
            </Box>
        </>
    );
}
