import React, { useEffect } from "react";
import * as STRINGS from "../resources/strings";
import { Box, Button, Container, Stack } from "@chakra-ui/react";
import Builder from "../Components/ResumeBuilder/Builder";
import ResumePreview from "../Components/ResumePreview/ResumePreview";
import ThemeSelect from "../Components/Theme/ThemeSelect";
import { useResume } from "../Components/ResumeBuilder/ResumeProvider";
import { MdOutlineFileDownload } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { useCookies } from "react-cookie";
import ExistsOrderUseCase from "../domain/ExistsOrderUseCase";
//import ReactPixel from "react-facebook-pixel";
import CVBuilderCategory, {
    DownloadPDF,
    InitCVBuilder,
} from "../resources/googleAnalytics/events/CVBuilderEvent";
import { TrackGoogleAnalyticsEvent } from "../resources/googleAnalytics";
import SaveResumeUseCase from "../domain/SaveResumeUseCase";
import LoadResumeUseCase from "../domain/LoadResumeUseCase";

const CVBuilder = () => {
    const { printElem } = useResume();
    const [cookies] = useCookies([STRINGS.COOKIE_NAME]);
    /** Save Resume */
    const {
        about,
        setAbout,
        educationList,
        setEducationList,
        skills,
        setSkills,
        languages,
        setLanguages,
        workList,
        setWorkList,
        projects,
        setProjects,
    } = useResume();

    useEffect(() => {
        /**
         * Check user has account
         */
        new ExistsOrderUseCase()
            .existsOrderUseCase(cookies[STRINGS.COOKIE_NAME])
            .then((res) => {
                if (res) {
                } else {
                    return (window.location = STRINGS.STRIPE_URL);
                }
            })
            .catch((e) => console.log("e", e));

        TrackGoogleAnalyticsEvent(
            CVBuilderCategory,
            InitCVBuilder,
            window.location.pathname
        );
        //ReactPixel.trackCustom("cv-builder", "init");

        new LoadResumeUseCase()
            .loadResumeUseCase(cookies[STRINGS.COOKIE_NAME])
            .then((res) => {
                if (res) {
                    setAbout(res.about);
                    setLanguages(
                        res.languages === undefined ? [] : res.languages
                    );

                    setSkills(res.skills === undefined ? [] : res.skills);
                    if (
                        res.educationList === null ||
                        res.educationList.length === 0
                    ) {
                        setEducationList({
                            id: "",
                            degree: "",
                            school: "",
                            startYr: 0,
                            endYr: 0,
                            grade: "",
                        });
                    } else {
                        setEducationList(res.educationList);
                    }

                    if (res.projects === null || res.projects.length === 0) {
                        setProjects({
                            id: "",
                            name: "",
                            description: "",
                            url: "",
                        });
                    } else {
                        setProjects(res.projects);
                    }

                    if (res.workList === null || res.workList.length === 0) {
                        setWorkList({
                            id: "",
                            position: "",
                            company: "",
                            type: "",
                            startDate: "",
                            endDate: "",
                            description: "",
                        });
                    } else {
                        setWorkList(res.workList);
                    }
                } else {
                    //Resume don't exist
                }
            })
            .catch((e) => console.log("e", e));
    }, [cookies]);

    const handlePrint = useReactToPrint({
        content: () => printElem.current,
    });

    const saveResume = () => {
        const resume = {
            about,
            educationList,
            skills,
            languages,
            workList,
            projects,
        };

        new SaveResumeUseCase()
            .saveResumeUseCase(cookies[STRINGS.COOKIE_NAME], resume)
            .then((res) => {})
            .catch((e) => console.log("e", e));
    };

    return (
        <Box>
            <Container maxW={"7xl"} px={8} my={3}>
                <Stack
                    justifyContent={"space-between"}
                    pt={4}
                    direction={{ base: "column", sm: "row" }}
                >
                    <ThemeSelect />
                    <Button
                        rightIcon={<MdOutlineFileDownload />}
                        onClick={() => {
                            //Save CV on Database
                            saveResume();
                            TrackGoogleAnalyticsEvent(
                                CVBuilderCategory,
                                DownloadPDF,
                                window.location.pathname
                            );
                            handlePrint();
                        }}
                        colorScheme={"purple"}
                    >
                        {STRINGS.DOWNLOAD}
                    </Button>
                </Stack>
            </Container>
            <Stack
                direction={{ base: "column", lg: "row" }}
                gap={2}
                mx={{ base: 2, md: 12 }}
                my={8}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
            >
                <Builder />
                <ResumePreview />
            </Stack>
        </Box>
    );
};

export default CVBuilder;
