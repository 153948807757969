import OpenAiDataProvider from "../infraestructure/OpenAi";

class RoastMyResumeUseCase {
    async roastMyResumeUseCase(img_url) {
        //const prompt = `How can I improve my resume that I am attaching? Please, give me tips, changes on the resume, grammar, key points, adding some examples for my resume. `;

        const prompt =
            "I'd like you to look over my resume and polish it with a long and amazing output. Please look it over and let me know what do you think. Fell free to give comments, key points, adding some examples, grammar, etc on the following: 1- What parts should I add to my resume to make it easier to read?  2- A way to make it easier to read my resume. 3- what kinds of numbers and scores should I put on my resume? 4- what kind of actions verbs should I pick? 5- Do I use to many buzzwords? If so, what should I use instead? Does that make sense?";
        return OpenAiDataProvider.completionRoastMyResume(img_url, prompt)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default RoastMyResumeUseCase;
